import { gql } from '@apollo/client';

// fetch all users
export const FETCHING_USERS = gql`
  query FetchUsers {
    allUsers {
      id
      firstName
      lastName
      email
      phone
      status
      jobTitle
      mobilePhone
      bio
      quote
      linkedinURL
      rank
      photo
      namePrefix
      intersellerCSVPermissions
      isFeatured
      fullName
      userTeamName
      userRoles {
        deletedAt
        role {
          name
        }
      }
    }
  }
`;

export const FETCHING_NOT_TERMINATED_USERS = gql`
  query FetchUsers {
    allActiveUsers {
      id
      firstName
      lastName
      email
      phone
      status
      jobTitle
      mobilePhone
      bio
      quote
      linkedinURL
      rank
      photo
      namePrefix
      intersellerCSVPermissions
      isFeatured
      fullName
      userTeamName
      userRoles {
        deletedAt
        role {
          name
        }
      }
    }
  }
`;

export const FETCHING_USERS_MINIMIZEVERSION = gql`
  query FetchUsers {
    allUsersDropDown {
      id
      fullName
      userTeamName
      userRoles {
        deletedAt
        role {
          name
        }
      }
    }
  }
`;

export const FETCHING_USERS_WITHOUT_ROLES = gql`
  query FetchUsers {
    allUsers {
      id
      fullName
    }
  }
`;

export const FETCH_ONE_AGREEMENT_JOB_ORDER = gql`
  query FetchAgreementOnJobOrder($agreementId: String!) {
    fetchAgreementOnJobOrder(agreementId: $agreementId) {
      id
      nickName
      isLegacy
      commissionPercentage
      replacementDays
      agreementType
      payableDays
    }
  }
`;

export const SYNC_INTERSELLER_USERS_TO_ATS = gql`
  mutation SyncIntersellerUsersToATS {
    syncIntersellerUsersToATS {
      message
      status
    }
  }
`;

// fetch all users
export const FETCHING_ALL_USERS_WITH_PAGINATION = gql`
  query FetchAllUsersWithPagination($userInput: FetchUserInput!) {
    fetchAllUsers(userInput: $userInput) {
      users {
        id
        firstName
        lastName
        email
        phone
        status
        jobTitle
        mobilePhone
        bio
        quote
        linkedinURL
        rank
        photo
        namePrefix
        intersellerCSVPermissions
        isFeatured
        fullName
        userTeamName
        userRoles {
          deletedAt
          role {
            name
          }
        }
      }
      count
    }
  }
`;

// fetch all users
export const FETCH_ALL_ACTIVE_RECRUITERS = gql`
  query FetchingAllActiveRecruiters($page: Int!, $limit: Int!) {
    fetchingAllActiveRecruiters(payload: { page: $page, limit: $limit }) {
      users {
        id
        firstName
        lastName
        email
        phone
        status
        jobTitle
        mobilePhone
        bio
        quote
        linkedinURL
        rank
        photo
        namePrefix
        intersellerCSVPermissions
        isFeatured
        fullName
      }
      count
    }
  }
`;

//fetch user details by id
export const FETCH_USER_DETAIL = gql`
  query GetUserDetail($userId: String!) {
    findUserById(findUser: $userId) {
      id
      firstName
      fullName
      lastName
      email
      phone
      status
      jobTitle
      mobilePhone
      bio
      quote
      outLookToken
      linkedinURL
      rank
      photo
      intersellerUserId
      namePrefix
      intersellerCSVPermissions
      intersellerUserKey
      isFeatured
      userTeamName
      userSignature
      dateOfBirth
      userManager {
        manager {
          fullName
          email
        }
      }
      earningGoal
      minimumExpectation
      userRoles {
        deletedAt
        role {
          name
        }
      }
    }
  }
`;

//query for the fetch all team
export const FETCH_TEAMS = gql`
  query GetAllTeams {
    getAllTeams {
      id
      name
      logoUrl
      createdAt
      archivedAt
      activatedAt
      teamUsers {
        id
        startAt
        endedAt
        isManager
        user {
          id
          fullName
          phone
          email
          photo
          jobTitle
        }
      }
    }
  }
`;
//query for the fetch all team
export const FETCH_TEAMS_FOR_EXECUTIVE = gql`
  query GetTeamsForExectives {
    teamForExecutive {
      id
      name
      logoUrl
      createdAt
      archivedAt
      activatedAt
      teamUsers {
        id
        startAt
        endedAt
        isManager
        user {
          fullName
          phone
          email
          photo
          jobTitle
          status
        }
      }
    }
  }
`;

// query for the fetch team users of the recquirter
export const FETCH_USER_TEAM_REPORT = gql`
  query GetUserTeamReport {
    userTeamMembers {
      id
      name
      logoUrl
      createdAt
      archivedAt
      activatedAt
      teamUsers {
        id
        startAt
        endedAt
        isManager
        user {
          fullName
          phone
          email
          photo
          jobTitle
        }
      }
    }
  }
`;
// query for the fetch team users
export const FETCH_MANAGER_TEAM = gql`
  query GetUserTeamReport {
    managerTeamMember {
      id
      name
      logoUrl
      createdAt
      archivedAt
      activatedAt
      teamUsers {
        id
        startAt
        endedAt
        isManager
        user {
          fullName
          phone
          email
          photo
          jobTitle
        }
      }
    }
  }
`;

// query for get single team details
export const FETCH_TEAM_DETAIL = gql`
  query GetTeamDetail($id: String!) {
    findTeamById(id: $id) {
      name
      id
      activatedAt
      logoUrl
      archivedAt
      creator {
        fullName
      }
      teamUsers {
        id
        startAt
        endedAt
        isManager
        user {
          fullName
          status
          photo
          jobTitle
          id
        }
      }
    }
  }
`;

//query for impersonate user according to role
export const FETCH_USER_IMPERSONATE = gql`
  query FetchUserForLogin($page: Int!, $limit: Int! , $searchText: String) {
    allImpersonateUsers(payload: { page: $page, limit: $limit , searchText: $searchText}) {
      users {
        id
        firstName
        lastName
        email
        phone
        status
        jobTitle
        mobilePhone
        bio
        quote
        linkedinURL
        rank
        photo
        namePrefix
        intersellerCSVPermissions
        isFeatured
        fullName
        userRoles {
          deletedAt
          role {
            name
          }
        }
      }
      count
    }
  }
`;

export const GET_ADMIN_STATS = gql`
  query GetAdminStats {
    adminStats {
      users
      activeTeams
      pendingTeams
      mergeCompanies
      pendingCompanies
      pendingContacts
      mergeCandidates
      pendingCandidates
      mergedContacts
      alreadySync
      needSync
      alreadySyncIntersellerWebhook
      needSyncIntersellerWebhook
      tbd
    }
  }
`;

// export const GET_INTERSELLER_USER_STATS = gql`
//   query GetIntersellerUserStats {
//     intersellerTeamUsersStats {
//       alreadySync
//       needSync
//     }
//   }
// `;

//Mutations

// export const SYNC_INTERSELLER_USERS = gql`
//   mutation SyncIntersellerUsers {
//     intersellerTeamUsers {
//       message
//       status
//     }
//   }
// `;

export const SYNC_INTERSELLER_WEBHOOKS = gql`
  mutation SyncIntersellerWebhooks {
    syncIntersellerWebhooks {
      message
      status
    }
  }
`;

export const PLACEMENT_ADMIN_STATS = gql`
  query PlacementStatsForAdmin {
    placementStatsForAdmin {
      approved
      pending
    }
  }
`;

//mutation for update user
export const EDIT_USER_DETAILS = gql`
  mutation UpdateUserByAdmin(
    $id: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $status: UserStatus
    $jobTitle: String
    $mobilePhone: String
    $bio: String
    $quote: String
    $linkedinURL: String
    $rank: Float
    $manager: String
    $isFeatured: Boolean
    $photo: String
    $password: String
    $intersellerUserId: String
    $intersellerCSVPermissions: Boolean
    $namePrefix: String
    $userSignature: String
    $dateOfBirth: String
    $minimumExpectation: String
    $earningGoal:String
  ) {
    updateUserByAdmin(
      userPayload: {
        userId: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        status: $status
        jobTitle: $jobTitle
        mobilePhone: $mobilePhone
        bio: $bio
        quote: $quote
        linkedinURL: $linkedinURL
        rank: $rank
        manager: $manager
        isFeatured: $isFeatured
        photo: $photo
        password: $password
        intersellerUserId: $intersellerUserId
        intersellerCSVPermissions: $intersellerCSVPermissions
        namePrefix: $namePrefix
        userSignature: $userSignature
        dateOfBirth: $dateOfBirth
        minimumExpectation: $minimumExpectation
        earningGoal: $earningGoal
      }
    ) {
      id
      fullName
    }
  }
`;

//Mutation for add new user
export const ADD_NEW_USER = gql`
  mutation AddNewUser(
    $firstName: String!
    $lastName: String!
    $password: String!
    $email: String!
    $phone: String
    $status: UserStatus
    $jobTitle: String
    $mobilePhone: String
    $bio: String
    $quote: String
    $linkedinURL: String
    $rank: Float
    $manager: String
    $role: [UserRolesEnum!]
    $photo: String
    $intersellerUserId: String
    $namePrefix: String
  ) {
    createUser(
      user: {
        firstName: $firstName
        lastName: $lastName
        password: $password
        email: $email
        phone: $phone
        status: $status
        jobTitle: $jobTitle
        mobilePhone: $mobilePhone
        bio: $bio
        quote: $quote
        linkedinURL: $linkedinURL
        rank: $rank
        manager: $manager
        role: $role
        photo: $photo
        intersellerUserId: $intersellerUserId
        namePrefix: $namePrefix
      }
    ) {
      user {
        fullName
      }
    }
  }
`;

//Mutation for add and update user roles

export const ADD_UPDATE_ROLE = gql`
  mutation UpdateUserRole($userId: String!, $roles: [UserRolesEnum!]!) {
    updateUserRole(userRole: { userId: $userId, role: $roles }) {
      user {
        fullName
        userRoles {
          role {
            name
          }
        }
      }
    }
  }
`;

//Mutation for create new team

export const CREATE_NEW_TEAM = gql`
  mutation CreateTeam($name: String!, $logoUrl: String) {
    createTeam(teamInput: { name: $name, logoUrl: $logoUrl }) {
      name
      id
    }
  }
`;

//Mutation for activate or deactivate team
export const ACTIVE_DEACTIVE_TEAM = gql`
  mutation AtiveOrDisactiveTeam($teamId: String!, $status: String!) {
    activeOrDeactiveTeam(status: $status, teamId: $teamId) {
      status
    }
  }
`;

//Mutation for update team
export const UPDATE_TEAM = gql`
  mutation UpdateTeam($id: String!, $name: String, $logoUrl: String) {
    updateTeam(updateInput: { id: $id, name: $name, logoUrl: $logoUrl }) {
      message
      status
    }
  }
`;

//Mutation for add members
export const ADD_TEAM_MEMBERS = gql`
  mutation AddTeamMembers($teamId: String!, $members: [Members!]!) {
    addTeamMembers(addMember: { teamId: $teamId, members: $members }) {
      message
      status
    }
  }
`;
//Mutation for remove team member
export const REMOVE_TEAM_MEMBER = gql`
  mutation RemoveTeamMember($teamId: String!, $userId: String!) {
    removeTeamMember(payload: { userId: $userId, teamId: $teamId }) {
      message
      status
    }
  }
`;

//Mutation for the set team manager
export const SET_TEAM_MANAGER = gql`
  mutation SetTeamManager($teamUserId: String!, $teamId: String!) {
    updateTeamManager(teamUserId: $teamUserId, teamId: $teamId) {
      message
      status
    }
  }
`;
//Mutation for login as user
export const LOGIN_AS = gql`
  mutation LoginAs($id: String!) {
    loginAs(id: $id) {
      access_token
      acl
    }
  }
`;

export const TERMINATE_AND_TRANSFER_USER = gql`
  mutation TerminateAndTransferUser(
    $from: String!
    $to: String!
    $termsAndConditions: Boolean!
  ) {
    terminateAndTransferUser(
      from: $from
      to: $to
      termsAndConditions: $termsAndConditions
    ) {
      message
      status
    }
  }
`;

export const SEND_TEST_EMAIL = gql`
  mutation SendTestEmail($userId: String!, $emailTo: String!) {
    sendingTestEmail(userId: $userId, emailTo: $emailTo) {
      message
      status
    }
  }
`;
