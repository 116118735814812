import { gql } from "@apollo/client";

// Queries

export const FETCH_AGREEMENTS = gql`
  query FetchAllAgreements(
    $page: Int!
    $limit: Int!
    $companyId: String
    $companyContactId: String
    $myCompanies: Boolean
    $myPendingActions: Boolean
    $columnFilters: AgreementColumnFilters!
  ) {
    allAgreements(
      payload: { page: $page, limit: $limit }
      filters: {
        companyId: $companyId
        companyContactId: $companyContactId
        myCompanies: $myCompanies
        myPendingActions: $myPendingActions
      }
      columnFilters:$columnFilters
    ) {
      agreements {
        id
        createdAt
        updatedAt
        nickName
        commissionPercentage
        signedAt
        status
        replacementDays
        hasFlatFee
        payableDays
        expiredAt
        agreementType
        envelopeId
        tempCommon
        documentUri
        User {
          id
          fullName
          phone
        }
        Company {
          id
          name
          companyOwner {
            id
            owner {
              id
              fullName
            }
          }
        }
        CompanyContact {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const FETCH_LEGACY_AGREEMENTS = gql`
  query FetchAllLegacyAgreements(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
    $companyContactId: String
    $myCompanies: Boolean
    $columnFilters: AgreementColumnFilters!
  ) {
    allLegacyAgreements(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
        companyContactId: $companyContactId
        myCompanies: $myCompanies
      }
      columnFilters:$columnFilters

    ) {
      agreements {
        id
        createdAt
        updatedAt
        nickName
        commissionPercentage
        signedAt
        status
        replacementDays
        payableDays
        expiredAt
        agreementType
        envelopeId
        tempCommon
        isLegacy
        documentUri
        User {
          id
          firstName
          phone
          lastName
        }
        Company {
          id
          name
          ownerId
        }
        CompanyContact {
          id

          firstName
          lastName
        }
      }
      count
    }
  }
`;

export const FETCH_MY_AGREEMENTS = gql`
  query FetchMyAgreements {
    myAgreementCount {
      id
      status
      isLegacy
    }
  }
`;

export const FETCH_ONE_AGREEMENT = gql`
  query FetchOneAgreement($agreementId: String!) {
    oneAgreement(agreementId: $agreementId) {
      id
      createdAt
      updatedAt
      nickName
      commissionPercentage
      signedAt
      status
      replacementDays
      payableDays
      expiredAt
      agreementType
      hasFlatFee
      envelopeId
      documentId
      isLegacy
      additionalInfo
      rateInfo
      tempCommon
      documentUri
      User {
        id
        firstName
        phone
        lastName
      }
      Company {
        id
        owner{
          id
        }
        name
        status
        size
        createdAt
        startedAt
        updatedAt
        fbUrl
        instaUrl
        linkedInUrl
        twitterUrl
        careerPageURL
        culture
        summary
        source
        sourceNote
        ownerAndSharees {
          id
          owner {
            id
            fullName
          }
        }
      }
      CompanyContact {
        id
        firstName
        fullName
        lastName
        jobTitle
        contactEmail {
          id
          email
          isPrimary
        }
      }
      Reviewer {
        id
        firstName
        phone
      }
      agreementHistory {
        id
        note
        oldStatus
        newStatus
        createdAt
        updatedAt
        Reviewer {
          id
          fullName
        }
        Agreement {
          id
        }
      }
    }
  }
`;

export const FETCH_ONE_AGREEMENT_ON_UPDATE = gql`
  query FetchOneAgreement($agreementId: String!) {
    oneAgreement(agreementId: $agreementId) {
      id
      createdAt
      updatedAt
      nickName
      commissionPercentage
      signedAt
      status
      replacementDays
      hasFlatFee
      payableDays
      expiredAt
      agreementType
      envelopeId
      documentId
      isLegacy
      rateInfo
      additionalInfo
      tempCommon
      documentUri
      User {
        id
        firstName
        phone
        lastName
      }
      Company {
        id
        domain {
          isPrimary
          domainUrl
        }
        name
        status
        size
        createdAt
        startedAt
        updatedAt
        fbUrl
        instaUrl
        linkedInUrl
        twitterUrl
        careerPageURL
        culture
        summary
        source
        sourceNote
        address {
          isPrimary
          isDeleted
          streetAddress
          city
          zipCode
          country
          state
          id
        }
        companyContacts {
          id
          firstName
          lastName
          fullName
          isPrivate
          jobTitle
          contactEmail {
            id
            email
            isPrimary
          }
        }
      }
      CompanyContact {
        id
        firstName
        fullName
        lastName
        isPrivate
        jobTitle
        contactEmail {
          id
          email
          isPrimary
        }
      }
      Reviewer {
        id
        firstName
        phone
      }
      agreementHistory {
        id
        note
        oldStatus
        newStatus
        createdAt
        updatedAt
        Reviewer {
          id
          fullName
        }
        Agreement {
          id
        }
      }
    }
  }
`;

export const FETCH_ONE_AGREEMENT_HISTORY = gql`
  query FetchAllAgreementHistory(
    $page: Int!
    $limit: Int!
    $agreementId: String!
  ) {
    agreementHistory(
      payload: { page: $page, limit: $limit }
      agreementId: $agreementId
    ) {
      agreements {
        id
        note
        oldStatus
        newStatus
        createdAt
        envelopeId
        documentId
        updatedAt
        Reviewer {
          id
          fullName
        }
      }
      count
    }
  }
`;

//Mutations

export const AgreementAll = gql`
  input AgreementAll {
    companyId: String
    companyContactId: String
    nickName: String
    status: AgreementStatus
    agreementType: AgreementType
    commissionPercentage: Float
    replacementDays: Int
    payableDays: Int
    signedAt: String
    expiredAt: String
    tempCommon: String
    note: String
    envelopeId: String
    documentId: String
    agreementId: String
  }
`;

export const CREATE_AGREEMENT = gql`
  mutation CreateAgreement($agreementInput: AgreementAll!) {
    createAgreement(agreementInput: $agreementInput) {
      nickName
      id
    }
  }
`;

export const CREATE_LEGAL_AGREEMENT = gql`
  mutation CreateLegacyAgreement($agreementInput: AgreementAll!) {
    createLegacyAgreement(agreementInput: $agreementInput) {
      nickName
      id
    }
  }
`;

//ongoing editing
export const UPLOAD_LEGAL_AGREEMENT = gql`
  mutation legalAgreementUpload($legalAgreementDto: LegalAgreementDto!) {
    LegalAgreementUpload(legalAgreementDto: $legalAgreementDto) {
      message
      status
    }
  }
`;

export const RECRUITERS_PROTOCOL = gql`
  mutation RecruiterProtocol($agreementInput: AgreementAll!) {
    recruiterProtocol(agreementInput: $agreementInput) {
      message
      status
    }
  }
`;

export const UPDATE_AGREEMENT = gql`
  mutation UpdateAgreement($agreementInput: AgreementAll!) {
    updateAgreement(agreementInput: $agreementInput) {
      message
      status
    }
  }
`;

export const UPDATE_TEMP_AGREEMENT = gql`
  mutation updateTempAndCustomAgreement($tempAgreementInput: TempAgreementInput!) {
    updateTempAndCustomAgreement(tempAgreementInput: $tempAgreementInput) {
      message
      status
    }
  }
`;

export const CLIENT_PREVIEW_PROTOCOL = gql`
  mutation ClientPreviewProtocol($agreementInput: AgreementAll!) {
    clientPreviewProtocol(agreementInput: $agreementInput) {
      message
      status
    }
  }
`;

export const MANAGER_ACCEPT_PROTOCOL = gql`
  mutation ManagerAcceptProtocol($agreementInput: AgreementAll!) {
    managerAcceptProtocol(agreementInput: $agreementInput) {
      message
      status
    }
  }
`;

export const MANAGER_REJECT_PROTOCOL = gql`
  mutation ManagerRejectProtocol($agreementInput: AgreementAll!) {
    managerRejectProtocol(agreementInput: $agreementInput) {
      message
      status
    }
  }
`;

export const EXECUTIVE_REJECT_PROTOCOL = gql`
  mutation ExecutiveRejectProtocol($agreementInput: AgreementAll!) {
    executiveRejectProtocol(agreementInput: $agreementInput) {
      message
      status
    }
  }
`;
export const VOID_AGREEMENT_DOCUSIGN = gql`
  mutation AvoidDocusingAgreement($envelopeId: String!, $voidReason: String!) {
    voidEnvelope(envelopeId: $envelopeId, voidReason: $voidReason) {
      message
      status
    }
  }
`;

export const RESEND_AGREEMENT_DOCUSIGN = gql`
  mutation resendDocusingAgreement($envelopeId: String!) {
    resendEnvelope(envelopeId: $envelopeId) {
      message
      status
    }
  }
`;

export const CORRECT_DOCUSIGN_EMAIL_WORKFLOW = gql`
  mutation correctDocusignEmailWorkflow($envelopeId: String!) {
    correctDocusignEmailWorkflow(envelopeId: $envelopeId) {
      message
      status
    }
  }
`;

export const RESEND_CONTACT_EMAIL = gql`
  query ResendContactEmail($contactId: String!) {
    getContactEmails(contactId: $contactId) {
      id
      email
    }
  }
`;

export const EXECUTIVE_ACCEPT_PROTOCOL = gql`
  mutation ExecutiveAcceptProtocol($agreementInput: AgreementAll!) {
    executiveAcceptProtocol(agreementInput: $agreementInput) {
      message
      status
    }
  }
`;

export const DELETE_AGREEMENT = gql`
  mutation DeleteAgreement($agreementId: String!) {
    deleteAgreement(agreementId: $agreementId) {
      message
      status
    }
  }
`;
export const DELETE_AGREEMENT_EXECUTIVE = gql`
  mutation DeleteAgreementExective($agreementId: String!) {
    deleteAgreementExecutive(agreementId: $agreementId) {
      message
      status
    }
  }
`;