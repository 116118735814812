import { useLazyQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  Badge,
  Button,
  Tooltip,
} from "reactstrap";
import InputField from "../../components/layout/InputField";
import TableComponent from "../../components/layout/TableComponent";
// import { AppContext } from "../../context";
import { compactObject } from "../../utils/commonFn";
import { COLUMN_INTERFACE_TYPES_FOR_CONTACT, COMPANY_DETAILS_ROUTE, CONTACTS_ROUTE, CONTACT_DETAIL_ROUTE, CONTACT_HEADER, PAGE_LIMIT } from "../../utils/constant";
import Pagination from "../../components/layout/Pagination";
import { FETCH_CONTACTS } from "./gql";
import "../company/company.scss";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import { AppContext } from "../../context";
import { getContactsParams } from "../../utils/getNoteParams";

import VIEW_ICON from "../../assets/images/view-icon.svg";
import EDIT_ICON from "../../assets/images/edit-icon-menu.svg";
import { pushInHistory } from "../../utils/helper";
import { useForm } from "react-hook-form";

let time=0
const AllContacts = ({ location, history }: RouteComponentProps) => {
  const fieldNames = [
    'fullNameSearch',
    'titleSearch',
    'emailSearch',
    'contactStatusSearch',
    'phoneSearch',
    'companyNameSearch',
  ];
  const defaultColumnFilters = {
    fullNameSearch: '',
    titleSearch: '',
    emailSearch: '',
    contactStatusSearch: '',
    companyNameSearch: '',
    phoneSearch:'',
    sorting:{
      columnName: '',
      columnValue: ''
    }
  };
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const { user, userRoles } = useContext(AppContext);
  const [errorMsg, setErrorMsg] = useState({});  
  const [columnFilters, setColumnFilters] =
  useState<COLUMN_INTERFACE_TYPES_FOR_CONTACT>(defaultColumnFilters);
  const { register, setValue,control} = useForm();
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);

  const [fetchAllContacts, { error, data, loading }] =
    useLazyQuery(FETCH_CONTACTS);

    const [activeToolTip, setActiveToolTip] = useState<boolean>(false);

    const [ids, setId] = useState<boolean>(false);
    const isOpenToolTip = (tId) => {
      if (tId === ids) {
        return activeToolTip;
      }
    };
    const toggles = (id) => {
      setId(id);
      setActiveToolTip(!activeToolTip);
    };

    const fetchContacts = useCallback(async () => {
      if (isSetTimeOut) {
        setQueryParams();
        await fetchAllContacts({
          variables: {
            limit,
            page: currentPage,
            columnFilters: columnFilters,
          },
        });
      } else {
        setQueryParams();
        setLimit(limit);
        window.clearTimeout(time);
        time = window.setTimeout(async () => {
          setCurrentPage(1);
          await fetchAllContacts({
            variables: {
              limit,
              page: currentPage,
              columnFilters: columnFilters,
            },
          });
        }, 1000);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilters, currentPage, limit]);
    
 

  const setQueryParams = () => {
    let newColumn: COLUMN_INTERFACE_TYPES_FOR_CONTACT = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;

    const criteria = compactObject({
      currentPage,
      limit,
      ...sorting, 
      ...rest
    });

    history.push(`${CONTACTS_ROUTE}?${new URLSearchParams(criteria).toString()}`);
  };

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      setCurrentPage(1);
      setLimit(limit);
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
      setIsSetTime(false)

    }
  };

  useEffect(() => {
    document.title = "Contacts - RealREPP";
    let params: any = getContactsParams(location.search);

    if (params) {
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
    }
    if (Object.entries(params?.columnFilters)?.length) {
      if (Object.entries(params?.columnFilters)?.length === 1) {
        setColumnFilters((prevFilters) => ({
          ...prevFilters,
          [Object.keys(params?.columnFilters)[0]]: Object.values(
            params?.columnFilters
          )[0],
        }));
      } else {
        const updatedFilters = { ...columnFilters };
        Object.entries(params?.columnFilters).forEach(([key, value]) => {
          updatedFilters[key] = value;
        });
        setColumnFilters(updatedFilters);
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchContacts();

  }, [fetchContacts]);

  return (
    <div className="admin-tabs team-tabs">
      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">Contacts</h5>
      </div>

      <div className="search-filter primaryHeaderSpacing d-flex justify-content-end">
          <div className="filter-select filterContacts">
          <button
            onClick={() => {
              history.push({
                pathname: '/create-contacts',
                state: { searchParameters: location?.search },
              });

              pushInHistory(CONTACTS_ROUTE, location.search);
            }}
            type="button"
            className="buttonGenericStyle filledButton mr-3"
          >
            Add
          </button>

          
          <button
            className="buttonGenericStyle filledButton ml-2"
            onClick={() => {
              setIsSetTime(true)
              setColumnFilters(defaultColumnFilters);
              fieldNames.forEach((fieldName) => {
                setValue(fieldName, defaultColumnFilters[fieldName]);
              });
              if (columnFilters?.sorting.columnName) {
                setValue(columnFilters?.sorting.columnName, false);
              }
              setErrorMsg({});
              fetchAllContacts({
                variables: {
                  limit,
                  page: currentPage,
                  searchText: '',
                  statusSearch: '',
                  columnFilters: columnFilters,
                },
              });
            }}
          >
            Clear
          </button>
        </div>
      </div>

      <div className="table-responsive tablePlacementResponsive">
        <TableComponent
          advanceSearching={true}
          tableHeaderAdvSeacrh={CONTACT_HEADER}
          handleChange={(filedName, value) => changeFiled(filedName, value)}
          columnFilters={columnFilters}
          register={register}
          setValue={setValue}
          control={control}
          tableName="Contacts"
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        >
          {loading && (
            <tbody>
              <tr>
                <td colSpan={12}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          {!loading && !data?.allContacts?.contacts?.length && (
            <tbody>
              <tr>
                <td colSpan={12} className="empty-table-td">
                  <NoDataFound text="No Contact Found!" />
                </td>
              </tr>
            </tbody>
          )}

          <tbody className="placement-table">
            {!loading &&
              data &&
              data?.allContacts &&
              data?.allContacts?.contacts?.map((item: any, index: number) => (
                <tr key={index}>
                  <td
                    className="route-link"
                    onClick={() => {
                      history.push({
                        pathname: `${CONTACT_DETAIL_ROUTE}/${item?.id}`,
                        state: { searchParameters: location.search },
                      });
                    }}
                  >
                    {item.fullName}
                    <span className="text-success">
                      {item?.isPrivate && '( Private )'}
                    </span>
                  </td>

                  <Tooltip
                    placement='top-start'
                    isOpen={isOpenToolTip(item.id)}
                    target={`tooltip-${item.id}`}
                    toggle={(e) => {
                      toggles(item.id);
                    }}
                  >
                    <p>{item?.company?.name}</p>
                  </Tooltip>

                  <td 
                    id={`tooltip-${item.id}`} className="route-link text-truncate max-200"
                    onClick={() => {
                      history.push({
                        pathname: `${COMPANY_DETAILS_ROUTE}/${item?.company?.id}`,
                        state: {
                          backUrl: CONTACTS_ROUTE,
                          searchParameters: location.search,
                        },
                      });

                      pushInHistory(CONTACTS_ROUTE, location.search);
                    }}
                  >
                    {item?.company?.name}
                  </td>

                  <td>{item.jobTitle}</td>
                  <td>
                    {item.contactEmail &&
                      item.contactEmail.map(
                        (email: any) => email.isPrimary && email.email
                      )}
                  </td>

                  <td className="badge-pading">
                    <Badge
                      color={
                        item.status === 'ACTIVE'
                          ? 'success'
                          : item.status === 'DO_NOT_CONTACT'
                          ? 'warning'
                          : item.status === 'DRAFT'
                          ? 'primary'
                          : ''
                      }
                      className="text-capitalize"
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    {item.contactPhone &&
                      item.contactPhone.map(
                        (phone: any) => phone.isPrimary && phone.number
                      )}
                  </td>

                  <td>
                    <Button
                      onClick={() => {
                        if (
                          item?.company?.companyAgreement &&
                          item?.company?.companyAgreement?.length
                        ) {
                          history.push(
                            `/agreements?currentPage=1&contactId=${item.id}&contactName=${item?.fullName}&companyId=${item?.company?.id}&companyName=${item?.company?.name}`
                          );

                          pushInHistory(CONTACTS_ROUTE, location.search);
                        }
                      }}
                      className="bg-transparent border-0"
                      size="sm"
                      style={{ color: '#0e79b6', fontSize: '12px' }}
                      disabled={
                        item?.company?.companyAgreement &&
                        item?.company?.companyAgreement?.length
                          ? false
                          : true
                      }
                    >
                      View
                    </Button>
                  </td>

                  <td>
                    <Link
                      className="mr-3"
                      onClick={() =>
                        pushInHistory(CONTACTS_ROUTE, location.search)
                      }
                      to={{
                        pathname: `${CONTACT_DETAIL_ROUTE}/${item?.id}`,
                        state: { searchParameters: location?.search },
                      }}
                    >
                      <img src={VIEW_ICON} alt="view" width="21px" />
                    </Link>

                    {(userRoles?.includes('ADMIN') ||
                      userRoles?.includes('EXECUTIVE') ||
                      item?.company?.owner?.id === user?.id ||
                      !item?.company?.owner) && (
                      <Link
                        onClick={() =>
                          pushInHistory(CONTACTS_ROUTE, location.search)
                        }
                        to={{
                          pathname: `/update-company-contact/${item?.id}`,
                          state: { searchParameters: location?.search },
                        }}
                      >
                        <img src={EDIT_ICON} alt="edit" />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </TableComponent>
      </div>

      {!!data?.allContacts?.contacts?.length && (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true)
              setCurrentPage(pageClicked);
              setQueryParams();
            }}
            pageCount={Math.ceil(data?.allContacts?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(currentPage * limit, data?.allContacts?.count)} of{' '}
                {data?.allContacts?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setQueryParams();
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      )}
      {error?.graphQLErrors[0]?.message}
    </div>
  );
};

export default AllContacts;
