import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { Badge, Card, CardBody, Col, Form, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../components/layout/CustomButton";
import { AppContext } from "../../context";
import { outlookAccountingLink } from "../../utils/Temp-Helper";
import { EMAIL_PATTERN, reactQuillReg } from "../../utils/constant";
import { replacePlaceholderWithResume } from "../../utils/helper";
import { EMAIL_USER_ACTIVITY_REPORT, GET_CC_EMAILS, GET_SINGLE_TEMPLATE } from "./gql";
import Loader from "../../components/layout/Loader";


const EmailModalActivityReport = ({ isOpen, ccContact, toggle, activityReport }) => {
  const { user } = useContext(AppContext)
  const [getCCEmails, { loading: loadingEmail, data: emailData }] = useLazyQuery(GET_CC_EMAILS,);
  const { data } = useQuery(GET_SINGLE_TEMPLATE, {
    variables: { module: 'User Activity Report' },
    onError: (err) => toast.error(err?.message)
  })
  const [sendEmail, { loading }] = useMutation(EMAIL_USER_ACTIVITY_REPORT, {
    onCompleted: () => {
      toast.success(`Email sent to ${activityReport?.fullName}`)
      toggle()
    }, onError: () => {
      toast.error("unable to send email")
    }
  })
  const [mailContact, setMailContact] = useState<{ ccContacts: string[]; bccContacts: string[] }>({
    ccContacts: [],
    bccContacts: [],
  });
  const [errMsg, setErrMsg] = useState('');
  const [bodyContent, setBodyContent] = useState<string | null>();
  const [payDate, setPayDate] = useState<string | null>()
  const [subject, setSubject] = useState<string | null>();

  const { control, errors, setValue } = useForm()

  useEffect(() => {
    if (activityReport) {
      getCCEmails({ variables: { id: activityReport?.userId } })
    }
  }, [getCCEmails, activityReport])

  useEffect(() => {
    if (activityReport) {
      setMailContact({
        ...mailContact,
        bccContacts: ['johnnyrenaudo@realrepp.com'],
        ...(emailData?.getUserManagerEmail && { ccContacts: [emailData?.getUserManagerEmail] })
      });
      setBodyContent(replacePlaceholderWithResume(data?.getTemplateByModule?.template, activityReport?.fullName.split(' ')[0], "{{firstName}}"))
      setSubject(data?.getTemplateByModule?.subject.replace("{{fullName}}", activityReport?.fullName).replace("{{activityDate}}", moment().month(parseInt(activityReport?.month) - 1).endOf('month').format('MM/DD/YY')))
    }
  }, [activityReport, emailData])

  useEffect(() => {
    if (!isOpen) {
      setBodyContent(null)
      setSubject(null)
      setPayDate(null)
    }
  }, [isOpen])


  const mailSelected = (e: any) => {
    if (e.keyCode === 13) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(e.target.value);
      if (test) {
        let mails = [...mailContact[e.target.name]];
        mails.push(e.target.value);
        setMailContact({ ...mailContact, [e.target.name]: mails });
        e.target.value = '';
        setErrMsg('');
      } else {
        setErrMsg('Email is not valid!');
      }
    }
  };

  const checkMail = (e) => {
    if (e.target.value) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(e.target.value);
      if (test) {
        let mails = [...mailContact[e.target.name]];
        mails.push(e.target.value);
        setMailContact({ ...mailContact, [e.target.name]: mails });
        e.target.value = '';
        setErrMsg('');
      } else {
        setErrMsg('Email is not valid!');
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="for-modal-styling"
      centered
    >
      <ModalHeader>Send Email</ModalHeader>

      <ModalBody >
        {loadingEmail ? <div className="loader"></div> : <>
          <div className="approval-content">
            <Col xs="5" className="mb-4 p-0">
              <Label>Pay Date<span className="text-danger">*</span></Label>
              <Controller
                name="payDate"
                defaultValue={payDate || ""}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Enter the pay date!"
                  }
                }}
                render={(field) => (
                  <Input
                    {...field}
                    type="date"
                    placeholder="pay date"
                    onChange={(e) => {
                      setValue("payDate", e.target.value)
                      setPayDate(moment(e.target.value).format('MM/DD/YY'))
                      setSubject(replacePlaceholderWithResume(subject, moment(e.target.value).format('MM/DD/YY'), "{{payDate}}"))

                    }}
                  />
                )}
              />
              <small className="text-danger">
                {errors?.invoiceNumber?.message}
              </small>
            </Col>
          </div>
          <>
            <div
              id="submittedEmail"
              className="writeup-wrap submission-package"
            >
              {user?.outLookAccountingToken ? (
                <Form>
                  <Card className="border-0">
                    <CardBody className="p-0">
                      <div className="dollar-addon-btn">
                        <Label className="text-14 text-blackele pb-3">Email</Label>
                        <div className="to-header">
                          <InputGroup className="shadow-none">
                            <InputGroupAddon addonType="prepend" className="styleCss">To</InputGroupAddon>
                            <Badge color="light" className="mx-1 my-auto text-truncate textTruncateWidth">
                              {activityReport?.email}</Badge>

                          </InputGroup>
                        </div>
                        <div className="border border-bottom-0"
                          style={{ borderColor: '#e5e9f8' }}>
                          <InputGroup className="shadow-none border-bottom-0 rounded-0">
                            <InputGroupAddon addonType="prepend" className="styleCss" >
                              Cc
                            </InputGroupAddon>
                            <div style={{ width: '74%' }} className="">
                              <div className="d-flex">
                                {mailContact.ccContacts?.map((item) => (
                                  <div className="position-relative d-flex">
                                    <Badge color="light" className="mx-1 my-auto text-truncate textTruncateWidth pr-4">
                                      {item}
                                    </Badge>
                                    <span
                                      className="forMailRemove text-dark"
                                      onClick={() => {
                                        setMailContact({
                                          ...mailContact,
                                          ccContacts: mailContact.ccContacts.filter((email) => email !== item),
                                        });
                                      }}>  x</span>
                                  </div>
                                ))}
                                <Input
                                  style={{
                                    border: '0px',
                                  }}
                                  autoComplete="off"
                                  name="ccContacts"
                                  onKeyUp={(e: any) => mailSelected(e)}
                                  onBlur={(e) => checkMail(e)}
                                />
                              </div>

                            </div>
                          </InputGroup>
                        </div>

                        <div
                          className="border border-bottom-0"
                          style={{ borderColor: '#e5e9f8' }}
                        >
                          <InputGroup className="shadow-none">
                            <InputGroupAddon
                              addonType="prepend"
                              className="styleCss"
                            >
                              Bcc
                            </InputGroupAddon>
                            <div style={{ width: '74%' }} className="">
                              <div className="d-flex">
                                {mailContact.bccContacts?.map((item) => (
                                  <div className="position-relative  d-flex">
                                    <Badge
                                      color="light"
                                      className="mx-1 my-auto text-truncate textTruncateWidth"
                                      style={{
                                        padding: '5px 20px 5px 5px ',
                                      }}
                                    >
                                      {item}
                                    </Badge>
                                    <span
                                      className="forMailRemove text-dark"
                                      onClick={() => {
                                        setMailContact({
                                          ...mailContact,
                                          bccContacts: mailContact.bccContacts.filter((email) => email !== item),
                                        });
                                      }}
                                    >
                                      x
                                    </span>
                                  </div>
                                ))}
                                <Input
                                  autoComplete="off"
                                  name="bccContacts"
                                  onKeyUp={(e: any) => mailSelected(e)}
                                  onBlur={(e) => checkMail(e)}
                                  className="border-0"
                                />
                              </div>
                            </div>
                          </InputGroup>
                        </div>

                        <div className="to-subject">
                          <InputGroup className="shadow-none">
                            <InputGroupAddon addonType="prepend" className="styleCss">
                              Subject
                            </InputGroupAddon>
                            <Input
                              autoComplete="off"
                              className="border-0"
                              name="subject"
                              value={subject!}
                              onChange={({ target: { value } }) => {
                                setSubject(value);
                              }}
                            />
                          </InputGroup>
                        </div>
                        <div>
                          <div className="editor-height">
                            <ReactQuill
                              value={bodyContent!}
                              onChange={(value) => {
                                if (reactQuillReg.test(value)) {
                                  setBodyContent(null);
                                } else {
                                  setBodyContent(value);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <small className="text-danger">{errMsg}</small>
                    </CardBody>
                  </Card>
                </Form>
              ) : (
                <Card className="border-0">
                  <CardBody className="p-0">
                    <div className="d-flex px-3 py-5 border">
                      <span>
                        Signin with your <strong> Accounting Outlook </strong> account
                        first!
                      </span>
                      {outlookAccountingLink}
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          </>
        </>}
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          onClick={toggle}
          className="buttonPrimary mr-3"
        >
          Cancel
        </button>
        <CustomButton
          buttonClick={() => {
            sendEmail({
              variables: {
                input: {
                  id: activityReport?.id,
                  bodyContent: bodyContent?.replaceAll('<p>', '').replaceAll('</p>', '<br>'),
                  subject,
                  ccContacts: mailContact.ccContacts,
                  bccContacts: mailContact.bccContacts
                }
              }
            })
          }}
          loading={loading}
          disabled={!payDate || !!errMsg || loading} buttonText="Send Email"
        />

      </ModalFooter>


    </Modal>
  )
}
export default EmailModalActivityReport