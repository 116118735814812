import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Badge, Button, Form } from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import CandidateWidgetPlacement from '../components/CandidateWidgetPlacement';
import CreatePlacementStepper from '../components/CreatePlacementStepper';
import CreatePlacementSteps from '../components/CreatePlacementSteps';
import JobOrderWidgetDetail from '../components/JobOrderWidgetDetail';
import {
  CREATE_PLACEMENT_RECRUITER_SPLIT,
  CREATE_PLACEMENT_SPLIT,
  GET_SINGLE_PLACEMENT_STEP_ONE,
  GET_SUBMISSION_BY_JOBAPPLICANT,
  PLACEMENT_CONTACT,
  PLACEMENT_SPLITTING,
  UPDATE_PLACEMENT,
} from '../gql';
import { UpdatePlacementInput } from '../placementInterface';
import '../placement.scss';
import Loader from '../../../../components/layout/Loader';
import { useHistory } from 'react-router';
import { AppContext } from '../../../../context';
import { GET_OWNER_SHAREES } from '../../../company/gql';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import moment from 'moment';
import { commaRemover } from '../../../../utils/helper';
import GoBack from '../../../../components/GoBack';
import { PLACEMENT_MANAGEMENT_ROUTE, VIEW_PLACEMENT_MANAGEMENT_ROUTE } from '../../../../utils/constant';

export interface IAccountManagerSelect {
  label: string;
  value: string;
}
export interface IAccountManagerPercentage {
  accountManager: IAccountManagerSelect;
  percentage: number;
}

export interface IRecruiterSelect {
  label: string;
  value: string;
}
export interface IRecruiterPercentage {
  recruiter: IRecruiterSelect;
  percentage: number;
}

const IndexUpdatePlacement = ({ location }) => {
  const history = useHistory();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();

  const jobId = new URLSearchParams(location?.search).get('jobId');
  const candidateId = new URLSearchParams(location?.search).get('candidate');
  const jobApplicantId = new URLSearchParams(location?.search).get(
    'jobApplicantId'
  );
  const comingState = location.state;

  const { user, userRoles } = useContext(AppContext);
  const [managerPermissions, setManagerPermissions] = useState<boolean>(false);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [readonlyStepThree, setReadonlyStepThree] = useState<boolean>(false);
  const [accountManagerSplitConfirmation, setAccountManagerSplitConfirmation] =
    useState<boolean>(false);
  const [recruiterSplitConfirmation, setRecruiterSplitConfirmation] =
    useState<boolean>(false);
  const [defaultPercentage, setDefaultPercentage] = useState<number>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [placementId, setPlacementId] = useState<string>();
  const [splitEdit, setSplitEdit] = useState<boolean>(false);
  const [recruiterSplitEdit, setRecruiterSplitEdit] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<string>();
  const [defaultOwner, setDefaultOwner] = useState<any>();
  const [defaultCandidateOwner, setDefaultCandidateOwner] = useState<any>();
  const [salaryType, setSalaryType] = useState<string>();
  const [finalPlacementValues, setFinalPlacementValues] = useState<any>();
  const [accountManagerList, setAccountManagerList] = useState<
    Array<IAccountManagerPercentage>
  >([]);
  const [recruiterList, setRecruiterList] = useState<
    Array<IRecruiterPercentage>
  >([]);

  const [getCompanyAndOwner, { data: companyOwnerSharees }] =
    useLazyQuery(GET_OWNER_SHAREES);

  const [_getSubmission, { data: _getSubmissionData }] = useLazyQuery(
    GET_SUBMISSION_BY_JOBAPPLICANT
  );

  // const [_createPlacement] = useMutation(CREATE_PLACEMENT);
  const [_updatePlacement] = useMutation(UPDATE_PLACEMENT);
  const [_createPlacementSplit] = useMutation(CREATE_PLACEMENT_SPLIT);
  const [_createPlacementRecruiterSplit] = useMutation(
    CREATE_PLACEMENT_RECRUITER_SPLIT
  );

  const [_placementSplit, { data: _placementSplitData }] =
    useLazyQuery(PLACEMENT_SPLITTING);

  const [_placementContact, { data: _placementContactData }] =
    useLazyQuery(PLACEMENT_CONTACT);
  const [
    _getPlacementStepOne,
    { data: _getPlacementStepOneData, loading: _getPlacementStepOneLoading },
  ] = useLazyQuery(GET_SINGLE_PLACEMENT_STEP_ONE);

  useEffect(() => {
    document.title = `Update Placement - RealREPP`;

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (jobId && candidateId) {
      _getPlacementStepOne({ variables: { candidateId, jobOrderId: jobId } });
    }

    if (jobApplicantId) {
      _getSubmission({ variables: { jobApplicantId } });
    }

    // eslint-disable-next-line
  }, [jobId, candidateId, jobApplicantId]);

  useEffect(() => {
    if (_getPlacementStepOneData?.getSinglePlacementStepOne) {
      setCurrentStep(1);
      setPlacementId(_getPlacementStepOneData?.getSinglePlacementStepOne?.id);

      // if unauthorized person is on placement page, let him/her push to dashboard page
      if (
        !(
          _getPlacementStepOneData?.getSinglePlacementStepOne?.recruiter?.id ===
            _getPlacementStepOneData?.getSinglePlacementStepOne
              ?.accountManager ||
          _getPlacementStepOneData?.getSinglePlacementStepOne?.accountManager
            ?.id === user?.id ||
          _getPlacementStepOneData?.getSinglePlacementStepOne?.recruiter?.id ===
            user?.id
        )
      ) {
        toast.info('You do not have permission to update the placement');

        setTimeout(() => {
          history.push(
            `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${_getPlacementStepOneData?.getSinglePlacementStepOne?.id}`
          );
        }, 2000);
      } else {
        // if the placement locked(in accounts person's hands), we just lock it
        if (
          _getPlacementStepOneData?.getSinglePlacementStepOne
            ?.placementStatus === 'ACCOUNTING_INVOICED' ||
          _getPlacementStepOneData?.getSinglePlacementStepOne
            ?.placementStatus === 'ACCOUNTING_APPROVED'
        ) {
          setReadonly(true);
          setReadonlyStepThree(true);
        }

        // if users are authorities (Manager of AM, Accounts Person, Admin, or Executive)
        else if (
          // _getPlacementStepOneData?.getSinglePlacementStepOne?.managerOfAM?.id ===
          //   user?.id ||
          userRoles?.includes(
            'ADMIN' ||
              userRoles?.includes('EXECUTIVE') ||
              (_getPlacementStepOneData?.getSinglePlacementStepOne
                ?.placementStatus === 'PENDING_ACCOUNTING' &&
                userRoles?.includes('ACCOUNTING_MANAGER'))
          )
        ) {
          setReadonly(false);
          setReadonlyStepThree(false);
        }

        // if recruiter, AM are same and they means AM is equal to current user
        else if (
          _getPlacementStepOneData?.getPlacementById?.accountManager?.id ===
            _getPlacementStepOneData?.getPlacementById?.recruiter?.id &&
          _getPlacementStepOneData?.getPlacementById?.accountManager?.id ===
            user?.id
        ) {
          setReadonly(false);
          setReadonlyStepThree(false);
        }

        // if its AM
        else if (
          _getPlacementStepOneData?.getSinglePlacementStepOne?.accountManager
            ?.id === user?.id
        ) {
          setReadonly(false);
          setReadonlyStepThree(true);
        }
        // if its recruiter
        else if (
          _getPlacementStepOneData?.getSinglePlacementStepOne?.recruiter?.id ===
          user?.id
          //   &&
          // _getPlacementStepOneData?.getSinglePlacementStepOne?.placementStatus ===
          //   "PENDING_RECRUITER"
        ) {
          setReadonly(true);
          setReadonlyStepThree(false);
        } else {
          setReadonly(true);
          setReadonlyStepThree(true);
        }
      }
    }

    // eslint-disable-next-line
  }, [_getPlacementStepOneData]);

  useEffect(() => {
    if (placementId) {
      _placementSplit({ variables: { placementId } });

      // Getting contacts in case its contract
      if (
        _getPlacementStepOneData?.getSinglePlacementStepOne?.placementType ===
        'CONTRACT'
      ) {
        _placementContact({ variables: { placementId } });
      }
    }

    // eslint-disable-next-line
  }, [placementId]);

  useEffect(() => {
    const accountManagingList = [...accountManagerList];
    const recruitingList = [...recruiterList];

    if (_placementSplitData && companyOwnerSharees?.getShareesAndOwner) {
      if (_placementSplitData?.placementSplitting?.length) {
        for (
          let index = 0;
          index < _placementSplitData?.placementSplitting.length;
          index++
        ) {
          const element = _placementSplitData?.placementSplitting[index];
          if (element?.splitType === 'RECRUITING') {
            setRecruiterSplitEdit(true);
            recruitingList.push({
              percentage: element?.splitPercentage,
              recruiter: {
                label: element?.user?.fullName,
                value: element?.user?.id,
              },
            });
          } else {
            setSplitEdit(true);

            accountManagingList.push({
              percentage: element?.splitPercentage,
              accountManager: {
                label: element?.user?.fullName,
                value: element?.user?.id,
              },
            });
          }
        }

        const owner = companyOwnerSharees?.getShareesAndOwner?.find(
          (item: any) => item?.ownerType === 'OWNER'
        )?.owner;

        setDefaultOwner({ label: owner?.fullName, value: owner?.id });
        setDefaultPercentage(100);

        setDefaultCandidateOwner({
          label:
            _getSubmissionData?.submissionCandidatePipelined?.createdBy
              ?.fullName,
          value:
            _getSubmissionData?.submissionCandidatePipelined?.createdBy?.id,
        });
        setAccountManagerList(accountManagingList);
        setRecruiterList(recruitingList);
      } else {
        if (companyOwnerSharees?.getShareesAndOwner) {
          const owner = companyOwnerSharees?.getShareesAndOwner?.find(
            (item: any) => item?.ownerType === 'OWNER'
          )?.owner;

          setDefaultOwner({ label: owner?.fullName, value: owner?.id });
          setDefaultPercentage(100);
        }
        if (_getSubmissionData?.submissionCandidatePipelined?.createdBy) {
          setDefaultCandidateOwner({
            label:
              _getSubmissionData?.submissionCandidatePipelined?.createdBy
                ?.fullName,
            value:
              _getSubmissionData?.submissionCandidatePipelined?.createdBy?.id,
          });
        }
      }
    }

    // eslint-disable-next-line
  }, [_placementSplitData, companyOwnerSharees?.getShareesAndOwner]);

  const recieveCompanyId = async (companyId: string) => {
    if (companyId) {
      getCompanyAndOwner({ variables: { id: companyId } });
    }
    setCompanyId(companyId);
  };

  const onBackBtnHandler = () => {
    reset({ ...finalPlacementValues });
  };

  const accountManagerListChange = (
    accountManagerPercentage: IAccountManagerPercentage
  ) => {
    const currentIndex = accountManagerList.indexOf(accountManagerPercentage);
    const newChecked = [...accountManagerList];

    if (currentIndex === -1) {
      newChecked.push(accountManagerPercentage);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAccountManagerList(newChecked);
  };

  const recruiterListChange = (recruiterPercentage: IRecruiterPercentage) => {
    const currentIndex = recruiterList.indexOf(recruiterPercentage);
    const newChecked = [...recruiterList];

    if (currentIndex === -1) {
      newChecked.push(recruiterPercentage);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRecruiterList(newChecked);
  };

  const onAccountManagerSplitting = async () => {
    const accountManagers = [...accountManagerList].map((item) => {
      return {
        accountManager: item?.accountManager?.value,
        percentage: item.percentage,
      };
    });

    const accountManagersplit = await _createPlacementSplit({
      variables: {
        createPlacementSplitDTO: {
          placementId,
          accountManagers,
        },
      },
    });

    if (accountManagersplit?.data) {
      accountManagerSplitConfirmationToggle();
      toast.success(
        `Account manager placement split ${
          splitEdit ? 'updated' : 'added'
        } successfully`
      );
      if (
        userRoles?.includes('ADMIN') ||
        userRoles?.includes('EXECUTIVE') ||
        _getPlacementStepOneData?.getSinglePlacementStepOne?.accountManager
          ?.id ===
          _getPlacementStepOneData?.getSinglePlacementStepOne?.recruiter?.id
      ) {
        setManagerPermissions(true);
        setCurrentStep(3);
      } else {
        setTimeout(() => {
          history.push({
            pathname: PLACEMENT_MANAGEMENT_ROUTE,
          });
        }, 800);
      }
    }
  };

  const onRecruiterSplitting = async () => {
    const recruiters = [...recruiterList].map((item) => {
      return {
        recruiter: item?.recruiter?.value,
        percentage: item.percentage,
      };
    });

    const recruiterSplit = await _createPlacementRecruiterSplit({
      variables: {
        createPlacementRecruiterSplitDTO: {
          placementId,
          isHigherAuthority:
            userRoles?.includes('ADMIN') ||
            userRoles?.includes('EXECUTIVE') ||
            userRoles?.includes('ACCOUNTING_MANAGER')
              ? true
              : false,
          recruiters,
        },
      },
    });

    if (recruiterSplit?.data) {
      recruiterSplitConfirmationToggle();
      history.push({
        pathname: PLACEMENT_MANAGEMENT_ROUTE,
      });
      toast.success(
        `Recruiter placement split ${
          recruiterSplitEdit ? 'updated' : 'added'
        } successfully`
      );
    }
  };

  const onPlacementEditSubmit = async (values: any) => {
    const endingDate = moment(values?.startDate)
      .add(parseInt(values?.assignedDuration) * 7, 'days')
      .format('YYYY-MM-DD');

    let finalValues: UpdatePlacementInput = {
      id: placementId,
      noteToAccounting: values?.noteToAccounting,
      placementNote: values?.placementNote,
      startDate: values?.startDate,
      estimateEndDate:
        values?.placementType?.value === 'DIRECT_HIRE'
          ? new Date()
          : endingDate,
      source: values?.placementSource,
      placementType: values?.placementType?.value,
    };

    if (values?.placementType?.value === 'DIRECT_HIRE') {
      const salary = commaRemover(values?.salary);

      const finalSalaryAmount =
        values?.salaryType === 'HOURLY'
          ? commaRemover(values?.salary) * 40 * 52
          : salary;

      const feeStructured = commaRemover(values?.feeStructured);

      let totalCommission = commaRemover(values?.totalFee);
      const adjustmentAmount = commaRemover(values?.adjustmentAmount);
      const netCommission = commaRemover(values?.netCommission);

      finalValues = {
        ...finalValues,
        adjustmentTotal: adjustmentAmount,
        adjustmentsReason: values?.adjustmentReason,
        feeStructured,
        agreement: values?.agreement?.value,
        commissionType: values?.commissionType?.value,
        salaryAmount: finalSalaryAmount,
        salaryType: values?.salaryType,
        payableTerm: values?.payableDays,
        replacementTerm: values?.replacementDays,
        totalCommission,
        netCommission,
      };
    } else {
      // Rate calculation
      const estimatedLessPayrollBurden = 0.1366 * commaRemover(values?.payRate);
      const insuranceCost = 0.15 * 8 * 5 * parseFloat(values?.assignedDuration);
      const peopleTwoFee = 0.05 * commaRemover(values?.billRate);
      const estimatedRecruiterProfit =
        commaRemover(values?.billRate) -
        commaRemover(values?.payRate) -
        0.15 -
        0.05 * commaRemover(values?.billRate);

      finalValues = {
        ...finalValues,
        assignmentDuration: parseFloat(values?.assignedDuration),
        billRate: commaRemover(values?.billRate),
        markupPercentage: parseFloat(values?.markupPercentage),
        overtimeBillRate: commaRemover(values?.overtimeBillRate),
        payRate: commaRemover(values?.payRate),
        timeCardApprover: values?.timeCardApprover?.value,
        estimatedLessPayrollBurden,
        insuranceCost,
        peopleTwoFee,
        estimatedRecruiterProfit,
      };
    }

    const placementUpdateSaving = await _updatePlacement({
      variables: {
        updatePlacementDTO: finalValues,
      },
    });

    if (placementUpdateSaving?.data) {
      toast.success('Placement is updated successfully');
      setCurrentStep(2);
      if (!_getPlacementStepOneData?.getSinglePlacementStepOne?.id) {
        setSplitEdit(true);
      }
      if (
        _getPlacementStepOneData?.getSinglePlacementStepOne?.placementStatus ===
          'PENDING_RECRUITER' &&
        _placementSplitData?.placementSplitting?.filter(
          (item) => item?.splitType === 'RECRUITING'
        )?.length
      ) {
        setRecruiterSplitEdit(true);
      }

      values.endDate = endingDate;
      setFinalPlacementValues(values);
      // reset({ ...finalPlacementValues });
    }
  };

  const accountManagerSplitConfirmationToggle = () => {
    setAccountManagerSplitConfirmation(!accountManagerSplitConfirmation);
  };

  const recruiterSplitConfirmationToggle = () => {
    setRecruiterSplitConfirmation(!recruiterSplitConfirmation);
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack
        url={PLACEMENT_MANAGEMENT_ROUTE}
        search={comingState?.searchParameters}
      />

      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">Placement Detail</h5>
      </div>

      {_getPlacementStepOneLoading && <Loader />}

      <CandidateWidgetPlacement
        watch={watch}
        fromWhichPlacement="placement-update"
        placement={_getPlacementStepOneData?.getSinglePlacementStepOne}
        id={candidateId}
        jobId={jobId}
        jobApplicantId={jobApplicantId}
        widgetPermissions={false}
        placementAccountsStatus={
          !(
            _getPlacementStepOneData?.getSinglePlacementStepOne
              ?.placementStatus === 'ACCOUNTING_APPROVED'
          )
        }
        refetchPlacement={() => {}}
      />
      <div className="mt-3">
        <JobOrderWidgetDetail
          watch={watch}
          fromWhichPlacement="placement-update"
          placement={_getPlacementStepOneData?.getSinglePlacementStepOne}
          candidateId={candidateId}
          jobApplicantId={jobApplicantId}
          placementAccountsStatus={
            !(
              _getPlacementStepOneData?.getSinglePlacementStepOne
                ?.placementStatus === 'ACCOUNTING_APPROVED'
            )
          }
          widgetPermissions={false}
          from="create"
          id={jobId}
          pipeCompanyIdToParent={recieveCompanyId}
          placementStatus={
            _getPlacementStepOneData?.getSinglePlacementStepOne?.placementStatus
          }
          refetchPlacement={() => {}}
        />
      </div>
      <div className="section-header">
        <h5 className="m-0">{'Update'} Candidate Placement </h5>

        <Badge
          color={
            _getPlacementStepOneData?.getSinglePlacementStepOne
              ?.placementStatus === 'ACCOUNTING_APPROVED' ||
            _getPlacementStepOneData?.getSinglePlacementStepOne
              ?.placementStatus === 'ACCOUNTING_CONFIRMED' ||
            _getPlacementStepOneData?.getSinglePlacementStepOne
              ?.placementStatus === 'ACCOUNTING_INVOICED'
              ? 'success'
              : _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'PROSPECT'
              ? 'warning'
              : _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'DRAFT' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'ASSIGNMENT_COMPLETED' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'ASSIGNMENT_CONVERTED'
              ? 'primary'
              : _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'REJECTED_MANAGER' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'REJECTED_AM' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'REJECTED_ACCOUNTING' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'REJECTED_RECRUITER' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'PLACEMENT_CANCELLED' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'ASSIGNMENT_CANCELLED'
              ? 'danger'
              : _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'PENDING_MANAGER' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'PENDING_AM' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'PENDING_RECRUITER' ||
                _getPlacementStepOneData?.getSinglePlacementStepOne
                  ?.placementStatus === 'PENDING_ACCOUNTING'
              ? 'info'
              : 'primary'
          }
          className="text-capitalize"
        >
          {_getPlacementStepOneData?.getSinglePlacementStepOne?.placementStatus}
        </Badge>
      </div>

      <div>
        <CreatePlacementStepper
          neverVisited={
            _getPlacementStepOneData?.getSinglePlacementStepOne
              ?.placementStatus === 'DRAFT'
              ? true
              : false
          }
          stepOneResetValues={() => {
            onBackBtnHandler();
          }}
          readonly={readonly}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
        <Form>
          {_getPlacementStepOneLoading ? (
            <Loader />
          ) : (
            <CreatePlacementSteps
              setValue={setValue}
              defaultCandidateOwner={defaultCandidateOwner}
              readonly={readonly}
              setError={setError}
              defaultPercentage={defaultPercentage}
              defaultOwner={defaultOwner}
              editPlacementData={
                _getPlacementStepOneData?.getSinglePlacementStepOne
              }
              managerPermissions={managerPermissions}
              editPlacementContact={_placementContactData?.placementContact}
              reset={reset}
              watch={watch}
              setFinalPlacementValues={setFinalPlacementValues}
              accountManagerListChange={accountManagerListChange}
              recruiterListChange={recruiterListChange}
              accountManagerList={accountManagerList}
              recruiterList={recruiterList}
              companyId={companyId}
              companyName={
                _getPlacementStepOneData?.getSinglePlacementStepOne?.companyName
              }
              readonlyStepThree={readonlyStepThree}
              control={control}
              errors={errors}
              salaryType={salaryType}
              setSalaryType={setSalaryType}
              setReadonlyStepThree={setReadonlyStepThree}
              register={register}
              currentStep={currentStep}
            />
          )}
        </Form>

        <div className="d-flex justify-content-end p-0 pb-3">
          <div>
            {currentStep !== 1 || _getPlacementStepOneData ? (
              true
            ) : false ? (
              <Button
                disabled={
                  placementId || _getPlacementStepOneData ? true : false
                }
                type="button"
                onClick={onBackBtnHandler}
                className="mr-2"
              >
                Back
              </Button>
            ) : null}

            {currentStep === 1 ? (
              <>
                {!readonly ? (
                  <CustomButton
                    buttonText={'Update Placement & Next'}
                    buttonColor={'primary'}
                    buttonType="button"
                    // className="mr-3"
                    buttonClick={handleSubmit(onPlacementEditSubmit)}
                  />
                ) : (
                  <CustomButton
                    buttonText={`Next`}
                    buttonColor={'primary'}
                    buttonType="button"
                    // className="mr-3"
                    buttonClick={() => {
                      setCurrentStep(2);
                    }}
                  />
                )}
              </>
            ) : currentStep === 2 ? (
              <>
                {!readonly ? (
                  <CustomButton
                    disabled={
                      accountManagerList?.length &&
                      accountManagerList?.reduce(function (acc, curr) {
                        return acc + curr.percentage;
                      }, 0) === 100
                        ? false
                        : true
                    }
                    buttonText={`${
                      splitEdit ? 'Update' : 'Add'
                    } Account Manager Split`}
                    buttonColor={'primary'}
                    buttonType="button"
                    buttonClick={accountManagerSplitConfirmationToggle}
                  />
                ) : (
                  <>
                    <CustomButton
                      buttonText={'Back'}
                      buttonColor={'primary'}
                      buttonType="button"
                      className="mr-3"
                      buttonClick={() => {
                        setCurrentStep(1);
                      }}
                    />

                    <CustomButton
                      buttonText={'Next'}
                      buttonColor={'primary'}
                      buttonType="button"
                      buttonClick={() => {
                        setCurrentStep(3);
                      }}
                    />
                  </>
                )}
              </>
            ) : currentStep === 3 ? (
              <>
                {readonlyStepThree ? (
                  <CustomButton
                    buttonText={'Back'}
                    buttonColor={'primary'}
                    buttonType="button"
                    buttonClick={() => {
                      setCurrentStep(2);
                    }}
                  />
                ) : (
                  <>
                    {managerPermissions ? (
                      <>
                        {/* {_getPlacementStepOneData?.getSinglePlacementStepOne
                        ?.placementStatus !== "PENDING_RECRUITER" ? ( */}
                        <CustomButton
                          // className="ml-3"
                          disabled={
                            recruiterList?.length &&
                            recruiterList?.reduce(function (acc, curr) {
                              return acc + curr.percentage;
                            }, 0) === 100
                              ? false
                              : true
                          }
                          buttonText={`${
                            recruiterSplitEdit ? 'Update' : 'Add'
                          } Recruiter Split`}
                          buttonColor={'primary'}
                          buttonType="button"
                          buttonClick={recruiterSplitConfirmationToggle}
                        />
                      </>
                    ) : (
                      <>
                        <CustomButton
                          buttonText={'Back'}
                          buttonColor={'primary'}
                          buttonType="button"
                          className="mr-3"
                          buttonClick={() => {
                            setCurrentStep(2);
                          }}
                        />
                        <CustomButton
                          disabled={
                            recruiterList?.length &&
                            recruiterList?.reduce(function (acc, curr) {
                              return acc + curr.percentage;
                            }, 0) === 100
                              ? false
                              : true
                          }
                          buttonText={`${
                            recruiterSplitEdit ? 'Update' : 'Add'
                          } Recruiter Split`}
                          buttonColor={'primary'}
                          buttonType="button"
                          buttonClick={recruiterSplitConfirmationToggle}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <ConfirmPopUp
        confirmText={`Are you sure you want to ${
          splitEdit ? 'update' : 'create'
        } account manager split?`}
        isOpen={accountManagerSplitConfirmation}
        toggle={accountManagerSplitConfirmationToggle}
        confirmAction={onAccountManagerSplitting}
        modalHeading="Confirmation Action"
        btnText={`${splitEdit ? 'Update' : 'Create'}`}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText={`Are you sure you want to ${
          recruiterSplitEdit ? 'update' : 'create'
        } recruiter split?`}
        isOpen={recruiterSplitConfirmation}
        toggle={recruiterSplitConfirmationToggle}
        confirmAction={onRecruiterSplitting}
        modalHeading="Confirmation Action"
        btnText={`${recruiterSplitEdit ? 'Update' : 'Create'}`}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default IndexUpdatePlacement;
