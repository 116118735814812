import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createMarkup } from "../utils/commonFn";
import CustomButton from "./layout/CustomButton";

const ViewMoreSummaryModal = ({ toggle, isOpen, data, heading }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} id="companySummaryModal">
      <ModalHeader toggle={toggle} className="px-4">
        {heading}
      </ModalHeader>
      <ModalBody
        className="p-4"
        style={{ overflow: "auto", maxHeight: "30rem" }}
      >
        <div className="for-word-break">
          {data ? (
            <pre
              className="text-field-value p-0 border-0"
              dangerouslySetInnerHTML={createMarkup(data)}
            />
          ) : (
            <div>---</div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          buttonText="Close"
          buttonColor="default"
          buttonType="button"
          buttonClick={toggle}
          className="btn-primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default ViewMoreSummaryModal;
