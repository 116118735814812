// packages block
import { useLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from 'reactstrap';
import client from '../../apollo';
// components block
import GoBack from '../../components/GoBack';
import CustomButton from '../../components/layout/CustomButton';
import InputField from '../../components/layout/InputField';
import { customSelectDropDown } from '../../components/styled/customSelect';
import CreateContactModal from '../company/company-components/CreateContactModal';
import Page404 from '../error';
import Preview from '../job-order/job-order-components/document-preview/Preview';
import AgreementRequest from './AgreementRequest';
// constants, graphql and interfaces
import uploadImg from '../../assets/images/upload.png';
import { AppContext } from '../../context';
import { ParamTypes } from '../../interfaces';
import {
  ADMIN_ROLE, AGREEMENTS_ROUTE, CONTACT, CUSTOM, DIRECT_HIRE, DRAFT, EXECUTIVE_APPROVED, EXECUTIVE_ROLE, OUT_FOR_SIGNATURE, SIGNED, TEMP, TEMP_AGREEMENT_STATUS, UPDATE_COMPANY_ROUTE, VIEW_AGREEMENT_ROUTE, VOIDED, bucketFolders, commissionPercentage, payableDays, replacementDays,
} from '../../utils/constant';
import { uploadImage } from '../../utils/helper';
import { FETCH_COMPANIES_OWNER_BASED, GET_COMPANY_BY_ID } from '../company/gql';
import {
  CREATE_AGREEMENT,
  FETCH_ONE_AGREEMENT_ON_UPDATE,
  UPDATE_AGREEMENT,
  UPDATE_TEMP_AGREEMENT,
} from './gql';
import {
  IComingState,
  ICompanyContactErrors,
  ICompanyErrors,
  ICreateCompany,
} from './interfaces';
import { companyAndAddressErrors } from './utils/companyErrors';
import { companyContactAndEmailErrors } from './utils/contactErrors';
import Select from 'react-select';

let time = 0;

const CreateUpdateAgreement = (): JSX.Element => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const location = useLocation<IComingState>();

  const comingState = location.state;

  // States
  const { userRoles, theme } = useContext(AppContext);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [flatFeeForCustom, setFlatFeeCustom] = useState<boolean>(false);
  const [ownershipError, setOwnershipError] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState<any>();
  const [currentUserCompanies, setCurrentUserCompanies] = useState<any>([]);
  const [, setSelectedCompanyId] = useState<string>('');
  const [, setSelectedCompanyName] = useState<string>('');
  const [, setSelectedContactId] = useState<string>('');
  const [, setSelectedContactName] = useState<string>('');
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [selectedCompanyContact, setSelectedCompanyContact] = useState<any>({});
  const [selectedContactEmail, setSelectedContactEmail] = useState<any>({});
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [agreementType, setAgreementType] = useState<string>(DIRECT_HIRE);
  const [editId, setEditId] = useState<string>('');
  const [companyErrors, setCompanyErrors] = useState<Array<ICompanyErrors>>([]);
  const [companyContactErrors, setCompanyContactErrors] = useState<
    Array<ICompanyContactErrors>
  >([]);
  const [contactsSelectedCompany, setContactsSelectedCompany] = useState<
    Array<any>
  >([]);
  const [contactToggle, setContactToggle] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isStatusUpdate, setIsStatusUpdate] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<string>('');

  const availableTempStatuses = !!editId
    ? currentStatus === OUT_FOR_SIGNATURE
      ? TEMP_AGREEMENT_STATUS.filter(
        (status) => status.value !== OUT_FOR_SIGNATURE
      )
      : currentStatus === EXECUTIVE_APPROVED
        ? TEMP_AGREEMENT_STATUS.filter(
          (status) => status.value !== VOIDED
        ) : TEMP_AGREEMENT_STATUS
    : [];

  const availableCustomStatuses = !!editId ?
    currentStatus === EXECUTIVE_APPROVED
      ? TEMP_AGREEMENT_STATUS.filter(
        (status) => status.value !== VOIDED
      ) : TEMP_AGREEMENT_STATUS
    : []


  const toggle = () => setContactToggle(!contactToggle);

  const {
    errors,
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    watch,
    setValue,
  } = useForm();

  const [_createAgreement, { loading: createAgreementLoading }] =
    useMutation(CREATE_AGREEMENT);
  const [_updateAgreement] = useMutation(UPDATE_AGREEMENT);

  const [fetchOneAgreement, { data: oneAgreement, loading }] = useLazyQuery(
    FETCH_ONE_AGREEMENT_ON_UPDATE
  );

  const [updateTempAndCustomAgreement, { loading: updateLoading }] = useMutation(
    UPDATE_TEMP_AGREEMENT
  );
  const [agreementModal, setAgreementModal] = useState(false);
  const [viewingDocument, setViewingDocument] = useState(false);
  const [documentUrl, setTempDocUrl] = useState<string>();

  const agreementFileViewToggle = () => setAgreementModal(!agreementModal);

  const framingDynamicDocument = async () => {
    try {
      setViewingDocument(true)

      const companyName = selectedCompany?.name;
      const companyContactName = `${getValues()?.companyContactId?.value?.firstName} ${getValues()?.companyContactId?.value?.lastName || ""}`;
      const jobTitle = `${getValues()?.companyContactId?.value?.jobTitle}`;
      const status = DRAFT;
      const commissionPercentage = parseFloat(watch('commissionPercentage'));
      const payableDays = parseInt(watch('payableDays'));
      const replacementDays = parseInt(watch('replacementDays'));
      const tempCommon = watch('tempCommon');
      const streetAddress =
        selectedCompany?.address?.length &&
        selectedCompany?.address[0]?.streetAddress;
      const city =
        selectedCompany?.address?.length && selectedCompany?.address[0]?.city;
      const state =
        selectedCompany?.address?.length && selectedCompany?.address[0]?.state;
      const zipCode =
        selectedCompany?.address?.length &&
        selectedCompany?.address[0]?.zipCode;
      const finalValues = {
        companyName,
        status,
        agreementType:
          agreementType === TEMP || agreementType === CUSTOM
            ? agreementType
            : DIRECT_HIRE,
        commissionPercentage,
        companyContactName,
        jobTitle:
          (agreementType === TEMP ||
            agreementType === CUSTOM ||
            agreementType === DIRECT_HIRE) && jobTitle,
        payableDays,
        replacementDays,
        tempCommon: agreementType === DIRECT_HIRE ? null : tempCommon,
        streetAddress,
        city,
        state,
        zipCode,
        createdAt: oneAgreement?.oneAgreement
          ? oneAgreement?.oneAgreement.createdAt
          : Date.now(),
      };

      const apiBaseURL =
        process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

      let response = await axios({
        method: 'post',
        url: `${apiBaseURL}${AGREEMENTS_ROUTE}/previewDocument`,
        responseType: 'blob',
        data: {
          ...finalValues,
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      });

      if (response.data) {
        setViewingDocument(false);
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        setTempDocUrl(fileURL);
        agreementFileViewToggle();
      }
    } catch (error) {
      setViewingDocument(false);
      toast.error('Error while previewing file.');
    }
  };

  const updateStatus = async (inputs: { status: string, additionalInfo: string }, companyId: string) => {
    try {
      if (currentStatus === EXECUTIVE_APPROVED) {
        if (!file) return setErrorMessage('Please select a file!');
      } else {
        if (!file && !fileName) return setErrorMessage('Please select a file!');
      }

      const { status, additionalInfo } = inputs;
      let fileUrl: File | string | undefined = '';

      if (!!file && typeof file !== 'string') {
        setUploadLoading(true);
        const { source } = await uploadImage(
          file,
          bucketFolders.agreement,
          false,
          false,
          false
        );

        fileUrl = source;
        setUploadLoading(false);
      }

      if (!fileUrl) {
        fileUrl = file;
      }
      const {
        data: { updateTempAndCustomAgreement: response },
      } = await updateTempAndCustomAgreement({
        variables: {
          tempAgreementInput: {
            agreementId: editId,
            companyId,
            status,
            documentUri: fileUrl || '',
            additionalInfo,
          },
        },
      });

      if (response?.status === 200) {
        toast.success(response?.message);
        history.push(`${VIEW_AGREEMENT_ROUTE}/${editId}`);
      }
    } catch (error) {
      toast.error('Failed to update status');
    }
  };

  const createAgreement = async (values: ICreateCompany) => {
    const companyId = selectedCompany.id;
    const companyContactId = selectedCompanyContact?.id;
    const status = DRAFT;
    const replacementDays = parseInt(values.replacementDays);
    const payableDays = parseInt(values.payableDays);
    const commissionPercentage = parseFloat(values.commissionPercentage);

    const agreementId = editId;
    const envelopeId = editId ? oneAgreement.oneAgreement.envelopeId : '';
    const finalValues = {
      ...values,
      companyId,
      status,
      agreementType:
        agreementType === TEMP || agreementType === CUSTOM
          ? agreementType
          : DIRECT_HIRE,
      commissionPercentage,
      companyContactId,
      payableDays,
      replacementDays,
      additionalInfo:
        (agreementType === TEMP || agreementType === CUSTOM) ? (additionalInfo || '').toString() : null,
      tempCommon: agreementType === DIRECT_HIRE ? null : values?.tempCommon,
    };

    if (editId) {
      const finalUpdatedValues = {
        ...values,
        companyId,
        agreementId,
        envelopeId,
        status: oneAgreement?.oneAgreement?.status ?? '',
        agreementType,
        commissionPercentage,
        companyContactId,
        payableDays,
        replacementDays,
        additionalInfo:
          (agreementType === TEMP || agreementType === CUSTOM) ? additionalInfo.toString() : null,
        tempCommon: agreementType === DIRECT_HIRE ? null : values?.tempCommon,
      };

      const agreementUpdated = await _updateAgreement({
        variables: { agreementInput: { ...finalUpdatedValues } },
      });

      if (agreementUpdated?.data) {
        toast.success('Agreement successfully updated!');
        history.push(`${VIEW_AGREEMENT_ROUTE}/${id}`);
      }
    } else {
      const agreementData = await _createAgreement({
        variables: { agreementInput: { ...finalValues } },
      });

      if (agreementData?.data) {
        toast.success('Agreement successfully created!');
        history.push(
          `${VIEW_AGREEMENT_ROUTE}/${agreementData?.data?.createAgreement?.id}`
        );
      }
    }

    if (!editId) {
      setAgreementType('');
      setSelectedCompanyContact({});
      setSelectedCompany({});
      setSelectedAddress({});
      reset();
    }
  };

  useEffect(() => {
    setEditId(id);
    if (id) fetchOneAgreement({ variables: { agreementId: id } });
  }, [fetchOneAgreement]);

  useEffect(() => {
    if (oneAgreement) {
      let values = { ...oneAgreement?.oneAgreement };

      setAgreementType(oneAgreement?.oneAgreement?.agreementType);
      setSelectedCompany(oneAgreement?.oneAgreement?.Company);
      setFlatFeeCustom(oneAgreement?.oneAgreement?.hasFlatFee)

      if (!(userRoles?.includes(ADMIN_ROLE) || userRoles?.includes(EXECUTIVE_ROLE))) {
        const filterContacts =
          oneAgreement?.oneAgreement?.Company?.companyContacts?.filter(
            (contact) => contact.isPrivate === false
          );
        setContactsSelectedCompany(filterContacts?.map((company) => {
          return { label: company?.firstName + " " + company?.lastName, value: company }
        }));
      } else {
        setContactsSelectedCompany(
          oneAgreement?.oneAgreement?.Company?.companyContacts?.map((company) => {
            return { label: company?.firstName + " " + company?.lastName, value: company }
          }));
      }

      const allPrimaryAddress =
        oneAgreement?.oneAgreement?.Company?.address?.find(
          (address) => address.isPrimary === true
        );
      setSelectedAddress(allPrimaryAddress);

      values.companyId = {
        value: oneAgreement?.oneAgreement?.Company?.id,
        label: oneAgreement?.oneAgreement?.Company?.name,
      };

      values.companyContactId = oneAgreement?.oneAgreement?.CompanyContact?.id;

      setSelectedCompanyContact(oneAgreement?.oneAgreement?.CompanyContact);
      const filteredEmail =
        oneAgreement?.oneAgreement?.CompanyContact?.contactEmail?.find(
          (email) => email.isPrimary === true
        );

      setSelectedContactEmail(filteredEmail);

      values.companyContactId = { value: oneAgreement?.oneAgreement?.CompanyContact, label: oneAgreement?.oneAgreement?.CompanyContact?.firstName + " " + oneAgreement?.oneAgreement?.CompanyContact?.lastName }
      values.expiredAt = oneAgreement?.oneAgreement?.expiredAt;
      values.tempCommon = oneAgreement?.oneAgreement?.tempCommon;
      values.agreementType = oneAgreement?.oneAgreement?.agreementType;
      values.commissionPercentage =
        oneAgreement?.oneAgreement?.commissionPercentage;
      values.replacementDays = oneAgreement?.oneAgreement?.replacementDays;
      values.payableDays = oneAgreement?.oneAgreement?.payableDays;
      setAdditionalInfo(oneAgreement?.oneAgreement?.additionalInfo);
      setFileName(oneAgreement?.oneAgreement?.documentUri || '');
      setFile(oneAgreement?.oneAgreement?.documentUri);
      setCurrentStatus(oneAgreement?.oneAgreement?.status);
      oneAgreement?.oneAgreement?.status === OUT_FOR_SIGNATURE ? values.status = oneAgreement?.oneAgreement?.status : values.status = null;

      setIsStatusUpdate(
        (oneAgreement?.oneAgreement?.agreementType === TEMP
          || oneAgreement?.oneAgreement?.agreementType === CUSTOM) &&
        (oneAgreement?.oneAgreement?.status === EXECUTIVE_APPROVED ||
          oneAgreement?.oneAgreement?.status === OUT_FOR_SIGNATURE)
      );

      reset({ ...values });
    }
    // eslint-disable-next-line
  }, [loading]);

  const agreementTypeHandler = (agreementTypeHandler: string) => {
    switch (agreementTypeHandler) {
      case DIRECT_HIRE:
        setAgreementType(agreementTypeHandler);
        setOpen(false);
        break;

      case TEMP:
        setAgreementType(agreementTypeHandler);
        setOpen(false);
        break;

      case CUSTOM:
        setAgreementType(agreementTypeHandler);
        setOpen(false);
        break;

      default:
        break;
    }
  };

  const companyFilter = (companyId: string) => {
    if (companyId) {
      setSelectedCompany({});
      setSelectedAddress({});
      const currentUserCompaniesFilter = currentUserCompanies?.find(
        (company) => company?.id === companyId
      );

      if (!(userRoles?.includes(ADMIN_ROLE) || userRoles?.includes(EXECUTIVE_ROLE))) {
        const filterContacts =
          currentUserCompaniesFilter?.companyContacts?.filter(
            (contact) => contact.isPrivate === false
          );
        setContactsSelectedCompany(filterContacts?.map((company) => {
          return { label: company?.firstName + " " + company?.lastName, value: company }
        }))
      } else {
        setContactsSelectedCompany(currentUserCompaniesFilter?.companyContacts?.map((company) => {
          return { label: company?.firstName + " " + company?.lastName, value: company }
        }));
      }

      if (
        currentUserCompaniesFilter &&
        Object.keys(currentUserCompaniesFilter)?.length
      ) {
        setSelectedCompany(currentUserCompaniesFilter);

        const currentUserCompaniesAddressFilter =
          currentUserCompaniesFilter?.address?.find(
            (address) => address.isPrimary === true
          );

        if (
          currentUserCompaniesAddressFilter &&
          Object.keys(currentUserCompaniesAddressFilter)?.length
        ) {
          setSelectedAddress(currentUserCompaniesAddressFilter);
        }

        const errors = companyAndAddressErrors(currentUserCompaniesFilter);
        const contactErrorExists = errors?.find(
          (contactError) => contactError.type === CONTACT
        );

        if (contactErrorExists) {
          setSelectedCompanyContact({});
        }

        setCompanyErrors(errors);
      }
    } else {
      setCompanyErrors([]);
      setSelectedCompany({});
      setSelectedAddress({});
    }
  };

  const companyContactFilter = (companyContactId: string) => {
    if (companyContactId) {
      setSelectedCompanyContact({});
      setSelectedContactEmail({});

      let contact = contactsSelectedCompany?.find(
        (contact) => contact && contact?.id === companyContactId
      );

      if (contact) {
        setSelectedCompanyContact(contact);
        setSelectedContactId(contact?.id);
        setSelectedContactName(contact?.fullName);

        const email = contact?.contactEmail?.find(
          (email) => email.isPrimary === true
        );

        if (email) {
          setSelectedContactEmail(email);
        } else {
          setSelectedContactEmail({});
        }

        const errors = companyContactAndEmailErrors(contact, email);

        setCompanyContactErrors(errors);
      }
    } else {
      setSelectedCompanyContact({});
      setCompanyContactErrors([]);
      setSelectedContactEmail({});
    }
  };

  // async select work
  const _filterMyCompanies = async (value: string) => {
    const { data, errors } = await client.query({
      query: FETCH_COMPANIES_OWNER_BASED,
      variables: {
        limit: 10,
        page: 1,
        searchText: value || '',
        ownerIdFlag: true,
      },
    });

    if (data?.fetchCompaniesOfCurrentUser?.length) {
      setCurrentUserCompanies(data?.fetchCompaniesOfCurrentUser);
      let currentCompany;
      if (selectedCompany?.id) {
        currentCompany = data?.fetchCompaniesOfCurrentUser?.find(
          (company) => company?.id === selectedCompany?.id
        );
        if (!currentCompany) {
          let { data } = await client.query({
            query: GET_COMPANY_BY_ID,
            variables: {
              companyId: selectedCompany?.id,
            },
          });
          currentCompany = data?.getCompany;
        }
      }

      if (selectedCompany?.id && currentCompany) {
        setContactsSelectedCompany(currentCompany?.companyContacts?.map((company) => {
          return { label: company?.firstName + " " + company?.lastName, value: company }
        }));
        const companyError = companyAndAddressErrors(currentCompany);
        const contactErrorExists = companyError?.find(
          (contactError) => contactError?.type === CONTACT
        );

        if (contactErrorExists) {
          setSelectedCompanyContact({});
        }
        setCompanyErrors(companyError);
      }
    }

    if (errors) {
      setOwnershipError(errors[0].message);
    }

    return data && data.fetchCompaniesOfCurrentUser
      ? data?.fetchCompaniesOfCurrentUser?.map((item) => ({
        value: item.id,
        label: item?.name,
      })) : [];
  };

  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterMyCompanies(value));
      } else window.clearTimeout(time);

      time = window.setTimeout(() => {
        resolve(_filterMyCompanies(value));
      }, 1000);
    });

  useEffect(() => {
    let stateData: any = location?.state;
    if (stateData?.companyId && currentUserCompanies?.length) {
      companyFilter(stateData?.companyId?.value);
      companyContactFilter(stateData?.companyContactId);
      reset({ ...stateData });
    }

    // eslint-disable-next-line
  }, [location?.state, currentUserCompanies]);

  const disablePreview = !(
    selectedCompany?.id &&
    getValues()?.companyContactId?.value?.id &&
    !!(
      watch('tempCommon')?.replace(/<[^>]*>?/gm, '') ||
      (watch('replacementDays') &&
        watch('commissionPercentage') &&
        watch('payableDays') &&
        watch('nickName'))
    )
  );

  const statusUpdateLoading = updateLoading || uploadLoading;
  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={AGREEMENTS_ROUTE} search={comingState?.searchParameters} />

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <h5 className="m-0">
          {editId ? 'Update' : 'Create'} Company Agreement
        </h5>
      </div>

      {!loading && oneAgreement?.oneAgreement?.status === SIGNED ? (
        <Page404></Page404>
      ) : (
        <div className="create-company jobOrderRevamp">
          <Form
            onSubmit={handleSubmit((values) =>
              isStatusUpdate
                ? updateStatus(values as { status: string, additionalInfo: string }, selectedCompany.id)
                : createAgreement(values as ICreateCompany)
            )}
          >
            <div>
              <h5>Basic Info</h5>

              <Row>
                <Col xl="4" lg="4" md="6" sm="12">
                  <div className="form-group mb-0">
                    <div className="d-flex justify-content-between">
                      <Label className="d-block mt-0">Company Name</Label>
                      {!currentUserCompanies?.length && (
                        <small className="text-danger">{ownershipError}</small>
                      )}
                    </div>

                    <Controller
                      control={control}
                      name="companyId"
                      rules={{
                        required: {
                          value: !isStatusUpdate,
                          message: 'Company should be selected!',
                        },
                      }}
                      render={({ onChange, ref }) => (
                        <AsyncSelect
                          value={getValues()?.companyId}
                          isSearchable
                          isDisabled={isStatusUpdate}
                          isClearable
                          ref={ref}
                          styles={{ ...customSelectDropDown(theme) }}
                          defaultOptions
                          onChange={(selected: any) => {
                            onChange(selected);
                            setSelectedCompanyId(selected?.value);
                            setSelectedCompanyName(selected?.label);
                            companyFilter(selected?.value);
                            setSelectedCompanyContact(null);
                            setValue('companyContactId', null);
                            history.replace({
                              pathname: location.pathname,
                              state: null,
                            });
                          }}
                          loadOptions={promiseOptions}
                          placeholder="Select Company"
                        />
                      )}
                    />

                    <div className="d-flex justify-content-between">
                      <div>
                        <small className="text-danger">
                          {errors?.companyId?.message}
                        </small>
                        <ul
                          className="ml-3 pl-1 mb-2 mt-2"
                          style={{ fontSize: '14px' }}
                        >
                          {companyErrors?.map((error, index) => {
                            return (
                              <li className="text-danger " key={index}>
                                {error.message}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="justify-content-end d-flex align-items-start mt-2">
                        <Button
                          className="bg-transparent border-0 text-info pr-0 pt-0"
                          onClick={() => {
                            history.push({
                              pathname: `${UPDATE_COMPANY_ROUTE}/${selectedCompany?.id}`,
                              search: '?from=non-legacy-agreements',
                              state: { ...getValues() },
                            });
                          }}
                          disabled={selectedCompany?.id ? false : true}
                        >
                          Update Company Info
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>

                {selectedCompany &&
                  !!Object.keys(selectedCompany).length &&
                  selectedAddress &&
                  !!Object.keys(selectedAddress).length && (
                    <Col md="6" sm="12" className="pt-3">
                      <Label className="d-block m-0 w-100">
                        <b>Company name:</b> {selectedCompany?.name}
                      </Label>

                      <Label className="d-block m-0 w-100">
                        <b>HQ address:</b> {selectedAddress?.streetAddress}
                      </Label>
                    </Col>
                  )}
              </Row>

              <Row>
                <Col xl="4" lg="4" md="6" sm="12">
                  <div className="form-group m-0">
                    <Label>Company Contact</Label>
                    <Controller
                      name="companyContactId"
                      rules={{
                        required: {
                          value: true,
                          message: "Company contact should be selected!",
                        },
                      }}
                      render={({ onChange, onBlur, value, name, ref }) => (
                        <Select
                          value={value}
                          name={name}
                          ref={ref}
                          onBlur={onBlur}
                          options={contactsSelectedCompany}
                          placeholder="Select Company Contact"
                          onChange={({ value, label }) => {
                            setValue("companyContactId", { value, label })
                            setSelectedCompanyContact(value)
                            setSelectedContactEmail(value?.contactEmail?.find(
                              (email) => email.isPrimary === true
                            ))
                            onChange({ value, label })
                          }}
                          styles={{ ...customSelectDropDown(theme) }}
                        />
                      )}
                      control={control}
                    />
                    <div className="d-flex justify-content-between">
                      <div>
                        <small className="text-danger">
                          {errors?.companyContactId?.message}
                        </small>
                        <ul
                          className="ml-3 pl-1 mb-2 mt-2"
                          style={{ fontSize: '14px' }}
                        >
                          {companyContactErrors?.map((error, index) => {
                            return (
                              <li className="text-danger " key={index}>
                                {error.message}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="justify-content-end d-flex align-items-start mt-2">
                        <Button
                          className="bg-transparent border-0 text-info pr-0 pt-0"
                          disabled={selectedCompany?.id ? false : true}
                          onClick={toggle}
                        >
                          Create Contact
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>

                {selectedCompany && !!Object.keys(selectedCompany)?.length ? (
                  <>
                    {selectedCompanyContact &&
                      !!Object.keys(selectedCompanyContact)?.length &&
                      selectedContactEmail &&
                      !!Object.keys(selectedContactEmail)?.length ? (
                      <Col md="6" sm="12" className="pt-no-label-agreement">
                        <Label className="d-block m-0 w-100">
                          <b>Contact name:</b>{' '}
                          {selectedCompanyContact?.firstName}{' '}
                          {selectedCompanyContact?.lastName}
                        </Label>

                        <Label className="d-block m-0 w-100">
                          <b>Contact Primary Email:</b>{' '}
                          {selectedContactEmail?.email}
                        </Label>
                      </Col>
                    ) : null}
                  </>
                ) : null}
              </Row>

              <Row>
                <Col xl="4" lg="4" md="6" sm="12" className="nick-name">
                  <InputField
                    inputtype="text"
                    label="Nickname"
                    name="nickName"
                    disabled={isStatusUpdate}
                    inputid="nickName"
                    placeholder="Nickname"
                    inputRef={register({
                      required: {
                        value: !isStatusUpdate,
                        message: 'Nickname is required!',
                      },
                    })}
                  />
                  <div className="d-flex flex-column">
                    <small className="text-info">
                      Example: "DH25PCT90REPL30NET".
                    </small>
                    <small className="text-danger">
                      {errors?.nickName?.message}
                    </small>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="mt-4">
              <h5 className="mb-3">Agreement Terms</h5>
              <div>
                <div className="d-flex d-flex-field justify-content-between create-agreement-radio pb-3">
                  <div className="d-flex create-agreement-radio radioWrapperWidth">
                    <Label className="radio-container mr-3">
                      Standard Direct Hire
                      <Input
                        type="radio"
                        checked={
                          !(
                            agreementType === TEMP ||
                            agreementType === CUSTOM
                          )
                        }
                        disabled={isStatusUpdate}
                        name="agreementType"
                        value="DIRECT_HIRE"
                        onChange={({
                          target,
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          agreementTypeHandler(target.value);
                        }}
                        innerRef={register({
                          required: {
                            value: !isStatusUpdate,
                            message: 'Please select any agreement type',
                          },
                        })}
                      />
                      <span className="checkmark" />
                    </Label>

                    <Label className="radio-container mr-3">
                      Temporary Agreement
                      <Input
                        type="radio"
                        checked={agreementType === TEMP}
                        name="agreementType"
                        value="TEMP"
                        disabled={isStatusUpdate}
                        onChange={({
                          target,
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          agreementTypeHandler(target.value);
                        }}
                        innerRef={register({
                          required: {
                            value: !isStatusUpdate,
                            message: 'Please select any agreement type',
                          },
                        })}
                      />
                      <span className="checkmark" />
                    </Label>

                    <Label className="radio-container mr-3">
                      Custom Agreement
                      <Input
                        checked={agreementType === CUSTOM}
                        type="radio"
                        name="agreementType"
                        disabled={isStatusUpdate}
                        value="CUSTOM"
                        onChange={({
                          target,
                        }: React.ChangeEvent<HTMLInputElement>) => {
                          agreementTypeHandler(target.value);
                        }}
                        innerRef={register({
                          required: {
                            value: !isStatusUpdate,
                            message: 'Please select any agreement type',
                          },
                        })}
                      />
                      <span className="checkmark" />
                    </Label>
                  </div>

                  {(agreementType === DIRECT_HIRE) && (
                    <div>
                      <button
                        className="buttonGenericStyle filledButton m-0"
                        type="button"
                        onClick={() => {
                          framingDynamicDocument();
                        }}
                        disabled={disablePreview}
                      >
                        {viewingDocument && <Spinner size="sm" />} Preview{' '}
                        {agreementType === DIRECT_HIRE
                          ? 'Direct Hire Agreement'
                          : agreementType === CUSTOM
                            ? 'Custom Agreement'
                            : 'Temp Agreement'}
                      </button>
                    </div>
                  )}
                </div>

                <small className="text-danger">
                  {errors?.agreementType?.message}
                </small>
              </div>

              {agreementType === DIRECT_HIRE && (
                <div className="agreementLabel pb-3">
                  <Row>
                    <Col xl="4" md="6" sm="12">
                      <FormGroup>
                        <Label>
                          Client Fee
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type={"select"}
                          disabled={isStatusUpdate}
                          name="commissionPercentage"
                          innerRef={register({
                            required: {
                              value: !isStatusUpdate,
                              message: 'Client Fee is required!',
                            },
                          })}
                        >
                          <option value="">Client Fee</option>
                          {commissionPercentage?.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item?.label}
                              </option>
                            );
                          })}
                        </Input>
                        <small className="text-danger">
                          {errors?.commissionPercentage?.message}
                        </small>
                      </FormGroup>
                    </Col>

                    <Col xl="4" md="6" sm="12">
                      <FormGroup>
                        <Label>
                          Replacement Days<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          disabled={isStatusUpdate}
                          name="replacementDays"
                          innerRef={register({
                            required: {
                              value: !isStatusUpdate,
                              message: 'Replacement days is required!',
                            },
                          })}
                        >
                          <option value="">Replacement Days</option>
                          {replacementDays?.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item?.label}
                              </option>
                            );
                          })}
                        </Input>
                        <small className="text-danger">
                          {errors?.replacementDays?.message}
                        </small>
                      </FormGroup>
                    </Col>

                    <Col xl="4" md="6" sm="12">
                      <FormGroup>
                        <Label>
                          Payable Days<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="select"
                          disabled={isStatusUpdate}
                          name="payableDays"
                          innerRef={register({
                            required: {
                              value: !isStatusUpdate,
                              message: 'Payable days is required!',
                            },
                          })}
                        >
                          <option value="">Payable Days</option>
                          {payableDays?.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item?.label}
                              </option>
                            );
                          })}
                        </Input>

                        <small className="text-danger">
                          {errors?.payableDays?.message}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              )}

              {agreementType === TEMP && (
                <>
                  <div className="agreementLabel pb-3">
                    <Row>
                      <Col xl="4" lg="4" md="6" sm="12" className="nick-name">
                        <InputField
                          inputtype="text"
                          label="Rate Info"
                          name="rateInfo"
                          isRequired
                          disable={isStatusUpdate}
                          inputid="rateInfo"
                          placeholder="Rate info"
                          inputRef={register({
                            required: {
                              value: !isStatusUpdate,
                              message: 'Rate info is required!',
                            },
                          })}
                        />
                        <div className="d-flex flex-column">
                          <small className="text-info">
                            Example: "50% mark up”.
                          </small>

                          <small className="text-danger">
                            {errors?.rateInfo?.message}
                          </small>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="agreementLabel pb-3">
                    <Row>
                      <Col md={12}>
                        <label>
                          Additional Info
                          <span className="text-danger"> *</span>
                        </label>
                        <Controller
                          control={control}
                          name="additionalInfo"
                          rules={{
                            required: {
                              value: true,
                              message: 'Additional info is required',
                            },
                          }}
                          defaultValue={''}
                          render={({ onChange, ref }) => (
                            <div className="editor-height">
                              <ReactQuill
                                defaultValue={additionalInfo || ''}
                                onChange={(value) => {
                                  setAdditionalInfo(
                                    value === '<p><br></p>' ? '' : value
                                  );
                                  onChange(
                                    value === '<p><br></p>' ? '' : value
                                  );
                                }}
                                readOnly={isStatusUpdate}
                              />

                              <small className="text-danger pt-2">
                                {errors?.additionalInfo?.message}
                              </small>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                  </div>

                  {isStatusUpdate && (
                    <div className="agreementLabel pb-3">
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              Status
                              <span className="text-danger">*</span>
                            </Label>

                            <Input
                              type="select"
                              name="status"
                              innerRef={register({
                                required: {
                                  value: isStatusUpdate,
                                  message: 'Status is required!',
                                },
                              })}
                            >
                              <option value="">Agreement Status</option>
                              {availableTempStatuses?.map((item, index) => {

                                return (
                                  <option key={index} value={item.value}>
                                    {item?.label}
                                  </option>
                                );
                              })}
                            </Input>

                            <small className="text-danger">
                              {errors?.status?.message}
                            </small>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <div>
                            <Row>
                              <div
                                className="user-info upload-btn-wrapper mt-3"
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <input
                                  type="file"
                                  name="image"
                                  id="image"
                                  accept={''}
                                  onChange={async ({ target: { files } }) => {
                                    setErrorMessage('');
                                    if (files) {
                                      let [file]: any = files;

                                      if (file.size > 25 * 1024 * 1024) {
                                        return setErrorMessage(
                                          'File size it too large (should be 25MB).'
                                        );
                                      }
                                      if (file?.type !== 'application/pdf') {
                                        return setErrorMessage(
                                          'Please select pdf format file'
                                        );
                                      }

                                      if (file) {
                                        setFileName(file?.name);
                                      }

                                      setFile(file);
                                    }
                                  }}
                                />

                                <img
                                  src={uploadImg}
                                  className="mx-3"
                                  alt="Upload File"
                                  style={{
                                    maxHeight: '26px',
                                    maxWidth: '26px',
                                    cursor: 'pointer',
                                  }}
                                ></img>

                                <Label className="pt-1 w-100" htmlFor="image">
                                  <a
                                    href="''"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="for-link-theme-color"
                                  >
                                    Upload File{' '}
                                  </a>
                                </Label>
                              </div>
                            </Row>
                            <div className="mt-2 text-info mb-2 text-truncate textTruncateWidth">
                              {fileName}
                            </div>
                            {errorMessage && (
                              <div className="text-danger">{errorMessage}</div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </>
              )}

              {agreementType === CUSTOM && (
                <Fragment>
                  <div className="agreementLabel pb-3">
                    <Row>
                      <Col xl="4" md="6" sm="12">
                        <FormGroup>
                          <Label>
                            Client{getValues('hasFlatFee') ? ` ` : ' Percentage '}Fee
                            <span className="text-danger">*</span>
                          </Label>
                          {getValues('hasFlatFee') || flatFeeForCustom ?
                            (<InputField
                              type="text"
                              name="commissionPercentage"
                              disabled={isStatusUpdate}
                              inputid="commissionPercentage"
                              placeholder="Client Fee"
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "Client Fee is required!",
                                },
                                pattern: {
                                  value: /^\d+(\.\d+)?$/,
                                  message: "Client Fee must be a number!",
                                },
                              })}
                              className="mb-0"
                            />)
                            :
                            (
                              <Input
                                type={"select"}
                                disabled={isStatusUpdate}
                                name="commissionPercentage"
                                innerRef={register({
                                  required: {
                                    value: !isStatusUpdate,
                                    message: 'Client Percentage Fee is required!',
                                  },
                                })}
                              >
                                <option value="">Client Percentage Fee</option>
                                {commissionPercentage?.map((item, index) => {
                                  return (
                                    <option key={index} value={item.value}>
                                      {item?.label}
                                    </option>
                                  );
                                })}
                              </Input>
                            )
                          }

                          <FormGroup className="ml-3 my-1 pl-1">
                            <Label className="m-0 d-flex items-center">
                              <Input
                                type="checkbox"
                                innerRef={register()}
                                name="hasFlatFee"
                                onChange={(e) => {

                                  setFlatFeeCustom(!flatFeeForCustom)
                                  setValue("hasFlatFee", e.target.checked)
                                }}
                              />
                              <span className="checkboxTextStyle ">
                                Flat Rate Fee
                              </span>
                            </Label>
                          </FormGroup>

                          <small className="text-danger">
                            {errors?.commissionPercentage?.message}
                          </small>
                        </FormGroup>
                      </Col>

                      <Col xl="4" md="6" sm="12">
                        <FormGroup>
                          <InputField
                            type="text"
                            label="Replacement Days"
                            name="replacementDays"
                            isRequired
                            disabled={isStatusUpdate}
                            inputid="replacementDays"
                            placeholder="Replacement Days"
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Replacement days is required!",
                              },
                              pattern: {
                                value: /^\d+$/,
                                message: "Replacement days must be a number!",
                              },
                            })}
                            className="mb-0"
                          />
                          <small className="text-danger">
                            {errors?.replacementDays?.message}
                          </small>
                        </FormGroup>
                      </Col>

                      <Col xl="4" md="6" sm="12">
                        <FormGroup>
                          <InputField
                            type="text"
                            label="Payable Days"
                            name="payableDays"
                            isRequired
                            disabled={isStatusUpdate}
                            inputid="payableDays"
                            placeholder="Payable Days"
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Payable days is required!",
                              },
                              pattern: {
                                value: /^\d+$/,
                                message: "Payable days must be a number!",
                              },
                            })}
                            className="mb-0"
                          />
                          <small className="text-danger">
                            {errors?.payableDays?.message}
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="agreementLabel pb-3">
                    <Row>
                      <Col md={12}>
                        <label>
                          Additional Info
                          <span className="text-danger"> *</span>
                        </label>
                        <Controller
                          control={control}
                          name="additionalInfo"
                          rules={{
                            required: {
                              value: true,
                              message: 'Additional info is required',
                            },
                          }}
                          defaultValue={''}
                          render={({ onChange, ref }) => (
                            <div className="editor-height">
                              <ReactQuill
                                defaultValue={additionalInfo || ''}
                                onChange={(value) => {
                                  setAdditionalInfo(
                                    value === '<p><br></p>' ? '' : value
                                  );
                                  onChange(
                                    value === '<p><br></p>' ? '' : value
                                  );
                                }}
                              // readOnly={isStatusUpdate}
                              />

                              <small className="text-danger pt-2">
                                {errors?.additionalInfo?.message}
                              </small>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                  </div>

                  {isStatusUpdate && (
                    <div className="agreementLabel pb-3">
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              Status
                              <span className="text-danger">*</span>
                            </Label>

                            <Input
                              type="select"
                              name="status"
                              innerRef={register({
                                required: {
                                  value: isStatusUpdate,
                                  message: 'Status is required!',
                                },
                              })}
                            >
                              <option value="">Agreement Status</option>
                              {availableCustomStatuses?.map((item, index) => {

                                return (
                                  <option key={index} value={item.value}>
                                    {item?.label}
                                  </option>
                                );
                              })}
                            </Input>

                            <small className="text-danger">
                              {errors?.status?.message}
                            </small>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <div>
                            <Row>
                              <div
                                className="user-info upload-btn-wrapper mt-3"
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <input
                                  type="file"
                                  name="image"
                                  id="image"
                                  accept={''}
                                  onChange={async ({ target: { files } }) => {
                                    setErrorMessage('');
                                    if (files) {
                                      let [file]: any = files;

                                      if (file.size > 25 * 1024 * 1024) {
                                        return setErrorMessage(
                                          'File size it too large (should be 25MB).'
                                        );
                                      }
                                      if (file?.type !== 'application/pdf') {
                                        return setErrorMessage(
                                          'Please select pdf format file'
                                        );
                                      }

                                      if (file) {
                                        setFileName(file?.name);
                                      }

                                      setFile(file);
                                    }
                                  }}
                                />

                                <img
                                  src={uploadImg}
                                  className="mx-3"
                                  alt="Upload File"
                                  style={{
                                    maxHeight: '26px',
                                    maxWidth: '26px',
                                    cursor: 'pointer',
                                  }}
                                ></img>

                                <Label className="pt-1 w-100" htmlFor="image">
                                  <a
                                    href="''"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="for-link-theme-color"
                                  >
                                    Upload File{' '}
                                  </a>
                                </Label>
                              </div>
                            </Row>
                            <div className="mt-2 text-info mb-2 text-truncate textTruncateWidth">
                              {fileName}
                            </div>
                            {errorMessage && (
                              <div className="text-danger">{errorMessage}</div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Fragment>
              )}

              {currentStatus !== VOIDED && <div className="text-right pt-1">
                <CustomButton
                  buttonText={
                    editId
                      ? isStatusUpdate
                        ? 'Update Agreement Status'
                        : 'Update Agreement'
                      : 'Create Agreement'
                  }
                  buttonColor="primary"
                  buttonType="submit"
                  className="big-width"
                  loading={createAgreementLoading || statusUpdateLoading}
                  disabled={
                    (companyErrors?.length === 0 &&
                      !(
                        companyContactErrors?.length === 0 &&
                        (agreementType === DIRECT_HIRE ||
                          agreementType === TEMP || agreementType === CUSTOM)
                      )) || createAgreementLoading
                  }
                />
              </div>}
            </div>
          </Form>
        </div>
      )}

      <CreateContactModal
        toggle={toggle}
        open={contactToggle}
        companyId={selectedCompany?.id}
        companyName={selectedCompany?.name}
        fetchCompany={() => {
          _filterMyCompanies('');
        }}
        preSelectContact={(preSelect) => {
          setCompanyContactErrors([]);
          setValue("companyContactId", { label: preSelect?.firstName + " " + preSelect?.lastName, value: preSelect })
          setSelectedCompanyContact(preSelect)
          setSelectedContactEmail(preSelect?.contactEmail?.find(
            (email) => email.isPrimary === true
          ))
        }}
      />

      <Modal
        isOpen={agreementModal}
        toggle={agreementFileViewToggle}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentUrl={documentUrl}
            documentName={`${watch('nickName')}.pdf`}
            isDownload={true}
          />
        </div>
      </Modal>

      <AgreementRequest isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
    </div>
  );
};

export default withRouter(CreateUpdateAgreement);