// packages block
import { useLazyQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
// components block
import Loader from "../../../components/layout/Loader";
// context, utils and svgs block
import downloadFileIcon from "../../../assets/images/download.svg";
import { AppContext } from "../../../context";
import { formatDateUSWithMoment } from "../../../utils/commonFn";
import { COMPANY_DETAILS_ROUTE, CUSTOM, DIRECT_HIRE, LEGAL_CONTRACT, LEGAL_DIRECT_HIRE, OUT_FOR_SIGNATURE, SIGNED, TEMP, VIEW_AGREEMENT_ROUTE } from "../../../utils/constant";
import { moneyFormatter, pushInHistory } from "../../../utils/helper";
import { GET_COMPANY_ADDRESS } from "../../company/gql";
import EditCompanyInfoModal from "./EditCompanyInfoModal";

export default function AgreementViewStuff({
  agreementLoading,
  company,
  companyContact,
  agreement,
  userIsReviewer,
  downloading,
  downloadDocumentAction,
  documentView,
  viewingDocumentAction,
  agreementType,
  refetchCompany,
  ...props
}) {
  const { user, userRoles } = useContext(AppContext)
  const [companyPrimaryAddress, setCompanyPrimaryAddress] = useState<any>(null)
  const [addressList, setAddressList] = useState<Array<{ label: string; value: string }>>([]);
  const [editModal, setEditModal] = useState<boolean>(false)
  const history = useHistory();
  const disableDownload = (agreement?.agreementType === TEMP || agreement?.agreementType === CUSTOM)
    && !(agreement?.status === OUT_FOR_SIGNATURE || agreement?.status === SIGNED)

  const [getCompanyAddress, { loading: companyAddressLoading, refetch }] = useLazyQuery(GET_COMPANY_ADDRESS, {
    onCompleted(data) {
      const { getCompanyAddress: { address } } = data
      setAddressList(data?.getCompanyAddress?.address?.map((v: any) => ({
        label: ['US', null, undefined, ''].includes(v?.country) ? `${v?.streetAddress}, ${v?.city}, ${v?.state}, ${v?.zipCode}, ${v?.country}`
          : `${v?.streetAddress}, ${v?.city}, ${v?.zipCode}, ${v?.country}`,
        value: v.id,
      })))

      if (!!address && !!address.length) {
        setCompanyPrimaryAddress((address.filter(addrs => addrs.isPrimary))[0])
      }
    }
  });

  const toggle = () => setEditModal(!editModal)

  const fetchCompanyAdress = useCallback(async () => {
    !!company && !!company.id && await getCompanyAddress({
      variables: { id: company.id, limit: 5, page: 1 },
    })
  }, [company, getCompanyAddress])

  useEffect(() => {
    if (!!company && !!company.id)
      fetchCompanyAdress()
  }, [company, fetchCompanyAdress])

  let companyContactEmail = '--';
  if (!!companyContact && !!companyContact?.contactEmail?.length) {
    let primaryEmail = (companyContact?.contactEmail.filter(email => email.isPrimary))[0]?.email
    companyContactEmail = !!primaryEmail ? primaryEmail : companyContact?.contactEmail[0]?.email || '--'
  }
  return (
    <div className="mt-2 view-company-agreement">
      {agreementLoading ? <Loader loaderClass="sm-height " /> :
        <div className="jobOrderRevamp">
          <Row>
            <Col xs={12} md={6} lg={6}>
              <div className="d-flex align-items-end my-2" >
                <h5 className="my-0">Basic Info </h5>
                {(userRoles?.includes('EXECUTIVE') || company?.owner?.id === user?.id) &&
                  <h6
                    className="route-link mx-3"
                    onClick={toggle}>
                    Edit
                  </h6>}
              </div>

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Company:</label>

                <div className="contentGrid">
                  <h6
                    className="route-link"
                    onClick={() => {
                      history.push({
                        pathname: `${COMPANY_DETAILS_ROUTE}/${company?.id}`,
                        state: {
                          backUrl: `${VIEW_AGREEMENT_ROUTE}/${props?.match?.params?.id}`,
                        },
                      });

                      pushInHistory(`${VIEW_AGREEMENT_ROUTE}/${props?.match?.params?.id}`)
                    }}
                  >
                    {company?.name}
                  </h6>
                </div>
              </div>

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Company LinkedIn Page:</label>

                <div className="contentGrid">
                  <h6 className="w-100">
                    <a
                      rel="noreferrer"
                      href={`${company?.linkedInUrl || "#"}`}
                      target="_blank"
                    >
                      {company?.linkedInUrl}
                    </a>
                  </h6>
                </div>
              </div>

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Company Careers Page:</label>

                <div className="contentGrid">
                  <a
                    rel="noreferrer"
                    href={`${company?.careerPageURL}`}
                    target="_blank"
                  >
                    {company?.careerPageURL}
                  </a>
                </div>
              </div>
              {companyAddressLoading ? <Loader /> : (
                <div className="d-flex  justify-content-between mb-2 contentGridLabel ">
                  <label>Primary Address:</label>

                  <div className="contentGrid">
                    <h6 className="w-100">
                      {
                        ['US', null, undefined, ''].includes(companyPrimaryAddress?.country) ? `${companyPrimaryAddress?.streetAddress}, ${companyPrimaryAddress?.city}, ${companyPrimaryAddress?.state}, ${companyPrimaryAddress?.zipCode}, ${companyPrimaryAddress?.country}` : `${companyPrimaryAddress?.streetAddress} ${companyPrimaryAddress?.city}, ${companyPrimaryAddress?.zipCode}, ${companyPrimaryAddress?.country}`
                      }
                    </h6>
                  </div>
                </div>
              )}

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Signed Date:</label>

                <div className="contentGrid">
                  <h6>
                    {agreement?.signedAt
                      ? formatDateUSWithMoment(agreement?.signedAt)
                      : "--"}{" "}
                    {/* TODO leagcy agreement ==> not show time */}
                    {(agreement?.agreementType !== LEGAL_CONTRACT && agreement?.agreementType !== LEGAL_DIRECT_HIRE) &&
                      (agreement?.signedAt
                        ? new Date(agreement?.signedAt).toLocaleTimeString()
                        : "--")}
                  </h6>
                </div>
              </div>
            </Col>

            <Col xs={12} md={6} lg={6}>
              <h5>Agreement Detail</h5>
              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Nickname:</label>

                <div className="contentGrid">
                  <h6>{agreement?.nickName}</h6>
                </div>
              </div>

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Contact Name:</label>

                <div className="contentGrid">
                  <h6>
                    {companyContact?.firstName || "--"}{" "}
                    {companyContact?.lastName || "--"}
                  </h6>
                </div>
              </div>

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Contact Title:</label>

                <div className="contentGrid">
                  <h6>{companyContact?.jobTitle || "--"}</h6>
                </div>
              </div>

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Contact Email:</label>

                <div className="contentGrid">
                  {companyContactEmail === '--' ? (
                    <h6>{companyContactEmail}</h6>
                  ) : (
                    <a href={`mailto:${companyContactEmail}`}>
                      <h6 className="route-link">{companyContactEmail}</h6>
                    </a>
                  )}
                </div>
              </div>

              <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                <label>Agreement Creator:</label>

                <div className="contentGrid">
                  <h6>{`${agreement?.User?.firstName || "--"}  ${agreement?.User?.lastName
                    }`}</h6>
                </div>
              </div>

              <div>
                <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                  <label>
                    {agreement?.status === SIGNED
                      ? "Download Signed Agreement"
                      : "Download Agreement"}
                  </label>

                  <div className="contentGrid">
                    <Button
                      disabled={downloading || disableDownload}
                      className="bg-transparent text-info border-0 p-0 for-link-theme-color d-flex"
                      onClick={downloadDocumentAction}
                    >
                      <img
                        src={downloadFileIcon}
                        alt="download"
                        className="mr-2 mb-1"
                      />
                      <small className="for-link-theme-color">
                        {downloading ? "Downloading..." : "Download"}
                      </small>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} md={6} lg={6}>
              <h5 className="mt-3">Status</h5>

              {agreementType === DIRECT_HIRE ? (
                <>
                  <div>
                    <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                      <label>Type:</label>

                      <div className="contentGrid">
                        <h6>{agreementType}</h6>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                      <label>Client Fee:</label>

                      <div className="contentGrid">
                        <h6>{agreement?.commissionPercentage}%</h6>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                      <label>Replacement Days:</label>

                      <div className="contentGrid">
                        <h6>{agreement?.replacementDays} Days</h6>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                      <label>Payable Days:</label>

                      <div className="contentGrid">
                        <h6>{agreement?.payableDays} Days</h6>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  {agreementType === DIRECT_HIRE ? null : (
                    <div className="agreement-details">
                      {agreementLoading ? (
                        <Loader loaderClass="sm-height " />
                      ) : (
                        <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                          <label>
                            {agreement?.isLegacy
                              ? "Legacy Agreement"
                              : agreementType === CUSTOM
                                ? "Custom Agreement"
                                : "Temporary Agreement"}{" "}
                          </label>

                          <div className="contentGrid">
                            <Button
                              disabled={documentView || disableDownload}
                              className="bg-transparent text-info border-0 p-0 for-link-theme-color d-flex"
                              onClick={() => {
                                viewingDocumentAction();
                              }}
                            >
                              <small className="for-link-theme-color">
                                {documentView
                                  ? "Viewing..."
                                  : "Click to see agreement"}
                              </small>
                            </Button>
                          </div>
                        </div>
                      )}

                      {agreementType === TEMP && <>
                        <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                          <label>Rate Info:</label>

                          <div className="contentGrid">
                            <h6>{agreement?.rateInfo}</h6>
                          </div>
                        </div>


                        <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                          <label>Additional Information:</label>
                        </div>

                        <div className="">
                          <div className="editor-height">
                            <ReactQuill
                              readOnly
                              defaultValue={agreement?.additionalInfo || ""}
                            />
                          </div>
                        </div>
                      </>}
                    </div>
                  )}
                </div>
              )}

              {agreementType === CUSTOM &&
                <>
                  <div>
                    <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                      <label>Client Fee:</label>
                      <div className="contentGrid">
                        <h6>{agreement?.hasFlatFee ? moneyFormatter(agreement?.commissionPercentage) : agreement?.commissionPercentage + '%'}</h6>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                      <label>Replacement Days:</label>

                      <div className="contentGrid">
                        <h6>{agreement?.replacementDays} Days</h6>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                      <label>Payable Days:</label>

                      <div className="contentGrid">
                        <h6>{agreement?.payableDays} Days</h6>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex  justify-content-between mb-2 contentGridLabel">
                    <label>Additional Information:</label>
                  </div>

                  <div className="">
                    <div className="editor-height">
                      <ReactQuill
                        readOnly
                        defaultValue={agreement?.additionalInfo || ""}
                      />
                    </div>
                  </div>
                </>}
            </Col>
          </Row>
        </div>}

      <EditCompanyInfoModal
        status={company?.status}
        addressList={addressList}
        companyAddressLoading={companyAddressLoading}
        refetchAddress={refetch}
        refetchCompany={refetchCompany}
        isOpen={editModal}
        companyId={company?.id}
        companyName={company?.name}
        toggle={toggle}
        companyLocation={companyPrimaryAddress}
        careerPageURL={company?.careerPageURL}
        linkedInUrl={company?.linkedInUrl}

      />
    </div>
  );
}
