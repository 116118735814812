import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tooltip,
} from 'reactstrap';
import CustomButton from '../../../components/layout/CustomButton';
import InputField from '../../../components/layout/InputField';
import { customSelectStyleNew } from '../../../components/styled/customSelect';
import { AppContext } from '../../../context';
import { getOptions, createMarkup } from '../../../utils/commonFn';
import {
  COUNTRY_STATES_PROVINCE,
  RateType,
  workplaceTypes,
  WorkType,
} from '../../../utils/constant';
import {
  jobDescriptionHandler,
  moneyFieldHandler,
} from '../../../utils/helper';
import { GET_CATEGORIES_LIST, GET_DEGREE, GET_SKILLS } from '../gql';

const JobOrderStatus = [
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Hold', value: 'HOLD' },
  { label: 'Filled By Us', value: 'FILLED_BY_US' },
  { label: 'Filled By Client', value: 'FILLED_BY_CLIENT' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Lost To Competitor', value: 'LOST_TO_COMPETITOR' },
  { label: 'Duplicated Job', value: 'DUPLICATED_JOB' },
];
const JobOrderStatusAfterActive = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Hold', value: 'HOLD' },
  { label: 'Filled By Us', value: 'FILLED_BY_US' },
  { label: 'Filled By Client', value: 'FILLED_BY_CLIENT' },
  { label: 'Close', value: 'CLOSE' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Lost To Competitor', value: 'LOST_TO_COMPETITOR' },
  { label: 'Duplicated Job', value: 'DUPLICATED_JOB' }
];

const JobDetails = ({
  register,
  jobOrderId,
  errors,
  control,
  setValue,
  getValues,
  watch,
  statusCheck,
  fromEdit,
  lastAdvertisedLocation,
  advertiseLocationList,
  locationOfJob
}: // viewDescription,
  any) => {
  const { data: skills } = useQuery(GET_SKILLS);
  const { data: degrees } = useQuery(GET_DEGREE);
  const [skillsList, setSkillsList] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [salaryMinimumRange, setSalaryMinimumRange] = useState<number>();
  const [salaryMaximumRange, setSalaryMaximumRange] = useState<number>();
  const [count, setCount] = useState<number>(0);
  // const [stepForSalary, setStepForSalary] = useState<number>(1);
  const { data: categories } = useQuery(GET_CATEGORIES_LIST);
  const [advertisedJobDescriptionModal, setAdvertisedJobDescriptionModal] =
    useState(false);
  const [advertisedJobDescription, setAdvertisedJobDescription] = useState('');

  const advertisedJobDescriptionToggle = () =>
    setAdvertisedJobDescriptionModal(!advertisedJobDescriptionModal);

  const filterSkills = (_newArray) => {
    let skills: any = [...skillsList].filter(
      (item: any) =>
        !Object.keys(
          _newArray.find((_item: any) => item.value === _item.value) || {}
        )?.length
    );
    setSkillsList(skills);
  };

  useEffect(() => {
    if (skills?.getSkills?.length) {
      let skillOptions = getOptions({
        data: skills?.getSkills,
      });

      setSkillsList(skillOptions);
    }

    // eslint-disable-next-line
  }, [skills]);

  const onRateChange = (value: string) => {
    if (value === 'ANNUAL') {
      setSalaryMinimumRange(30000);
      setSalaryMaximumRange(500000);
      // setStepForSalary(5000);
    } else if (value === 'HOURLY') {
      setSalaryMinimumRange(15);
      setSalaryMaximumRange(150);
      // setStepForSalary(2);
    }
  };
  useEffect(() => {
    setCount((locationOfJob.split(",").length) - 1)
    if ((locationOfJob.split(",").length - 1) === 3) {
      setValue('state', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationOfJob])

  useEffect(() => {
    const target = getValues()?.rateType;
    if (target) {
      if (target?.value === 'ANNUAL') {
        setSalaryMinimumRange(30000);
        setSalaryMaximumRange(500000);
        // setStepForSalary(5000);
      } else if (target?.value === 'HOURLY') {
        setSalaryMinimumRange(15);
        setSalaryMaximumRange(150);
        // setStepForSalary(2);
      }
    }

    // eslint-disable-next-line
  }, [getValues()]);

  useEffect(() => {
    if (!fromEdit) {
      setSalaryMinimumRange(30000);
      setSalaryMaximumRange(500000);
      // setStepForSalary(5000);
    }
  }, [fromEdit]);

  const ToolTipToggle = () => setTooltipOpen(!tooltipOpen);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  // const loc =
  //   `${singleAdvertisedLocations?.getSingleLocation?.city}` +
  //   ", " +
  //   `${singleAdvertisedLocations?.getSingleLocation?.state}`;

  const handleJobDescription = () => {
    let desc = getValues()?.advertisedJobDescription;
    desc = desc?.replaceAll('[JOBLOCATION]', lastAdvertisedLocation);
    if (advertiseLocationList?.length) {
      let cities = advertiseLocationList?.map((item) =>
        item.city.toLowerCase()
      );
      if (cities.includes('san francisco')) {
        desc = jobDescriptionHandler(desc, true);
      } else {
        desc = jobDescriptionHandler(desc, false);
      }
    }
    setAdvertisedJobDescription(desc);
    advertisedJobDescriptionToggle();
  };

  const { theme } = useContext(AppContext);

  return (
    <Card className="border-0">
      <div className="d-flex align-items-center justify-content-between flex-wrap pb-3">
        <h5 className="m-0">Additional Info</h5>

        <div className="mobileResponsiveFlexAlign">
          <span className="mr-2 switchLabel">
            Show Salary on Advertised Job Ad
          </span>
          <label className="switch mt-1 mr-3">
            <Input
              type="checkbox"
              name="showSalary"
              innerRef={register()}
              defaultChecked={true}
            />
            <span className="slider"></span>
          </label>

          <span className="mr-2 switchLabel">Featured Job</span>
          <label className="switch mt-1">
            <Input type="checkbox" name="isFeatured" innerRef={register()} />
            <span className="slider"></span>
          </label>
        </div>
      </div>

      <div>
        <div className="user-basic-form">
          <Row>
            {!jobOrderId && (
              <>
                <Col xl="4" lg="4" md="6" sm="12" xs="12">
                  <Label>Advertised City</Label>

                  <InputField
                    inputtype="text"
                    inputid="city"
                    placeholder="City"
                    inputRef={register({
                      required: {
                        value: false,
                        message: 'City is required!',
                      },
                    })}
                    errMsg={errors?.city?.message}
                  />
                </Col>
                <Col xl="4" lg="4" md="6" sm="12" xs="12">
                  <Label>Advertised State</Label>

                  <InputField
                    inputtype="select"
                    selectItems={COUNTRY_STATES_PROVINCE('US')}
                    disable={count === 3}
                    inputid="state"
                    placeholder="Please select any state"
                    inputRef={register({
                      required: {
                        value: false,
                        message: 'State is required!',
                      },
                    })}
                    errMsg={errors?.state?.message}
                  />
                </Col>
              </>
            )}
            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>
                  Status <span className="text-danger">*</span>
                </Label>
                <Controller
                  name="status"
                  rules={{
                    required: {
                      value: statusCheck ? false : true,
                      message: 'Status must be selected!',
                    },
                  }}
                  defaultValue={JobOrderStatus[0]}
                  render={() => (
                    <Select
                      options={
                        Object.values(getValues()?.status || {})?.includes(
                          'DRAFT'
                        )
                          ? JobOrderStatus
                          : JobOrderStatusAfterActive
                      }
                      value={getValues()?.status}
                      placeHolder="Status"
                      isDisabled={statusCheck}
                      onChange={(object) => {
                        if (object?.value) {
                          setValue('status', object);
                        }
                      }}
                      styles={customStyles}
                    />
                  )}
                  control={control}
                  styles={customStyles}
                />
                <small className="text-danger">{errors?.status?.message}</small>
              </FormGroup>
            </Col>

            <Col
              xl="4"
              lg="4"
              md="6"
              sm="12"
              xs="12"
              className="dollar-addon-btn"
            >
              <FormGroup>
                <Label>
                  Travel Percentage
                  {watch('status')?.value === 'ACTIVE' && (
                    <span className="text-danger">*</span>
                  )}
                </Label>
                <Controller
                  name="travelPercentage"
                  defaultValue=""
                  rules={{
                    required: {
                      value: watch('status')?.value === 'ACTIVE' ? true : false,
                      message: 'Travel percentage must be selected!',
                    },
                    pattern: {
                      value: /^\d*(\.\d+)?$/,
                      message: 'Positions must be number!',
                    },
                  }}
                  render={() => (
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                      <Input
                        placeholder="Travel Percentage"
                        name="travelPercentage"
                        min={0}
                        defaultValue={0}
                        type="text"
                        step="0.01"
                        innerRef={register}
                      />
                    </InputGroup>
                  )}
                  control={control}
                />
                <small className="text-danger">
                  {errors?.travelPercentage?.message}
                </small>
              </FormGroup>
            </Col>

            <Col mdxl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>
                  Work Type
                  {watch('status')?.value === 'ACTIVE' && (
                    <span className="text-danger">*</span>
                  )}
                </Label>
                <Controller
                  name="workType"
                  rules={{
                    required: {
                      value: watch('status')?.value === 'ACTIVE' ? true : false,
                      message: 'Must Select workType!',
                    },
                  }}
                  defaultValue={{
                    label: 'Direct Hire',
                    value: 'DIRECT_HIRE',
                  }}
                  placeHolder="Work Type"
                  render={() => (
                    <Select
                      options={WorkType}
                      value={getValues()?.workType}
                      onChange={(object) => {
                        setValue('workType', object);
                      }}
                      styles={{ ...customSelectStyleNew(theme) }}
                    />
                  )}
                  control={control}
                  styles={customStyles}
                />

                <small className="text-danger">
                  {errors?.workType?.message}
                </small>
              </FormGroup>
            </Col>

            <Col mdxl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>Rate Type</Label>
                <Controller
                  name="rateType"
                  rules={{
                    required: {
                      value: false,
                      message: 'Rate Type must be selected!',
                    },
                  }}
                  defaultValue={{
                    label: 'Annual',
                    value: 'ANNUAL',
                  }}
                  placeholder="Rate Type"
                  render={() => (
                    <Select
                      options={RateType}
                      value={getValues()?.rateType}
                      onChange={(object) => {
                        if (object?.value) {
                          onRateChange(object?.value);
                        }
                        setValue('rateType', object);
                      }}
                      styles={{ ...customSelectStyleNew(theme) }}
                    />
                  )}
                  control={control}
                  styles={customStyles}
                />
              </FormGroup>
            </Col>

            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>
                  Category
                  {watch('status')?.value === 'ACTIVE' && (
                    <span className="text-danger">*</span>
                  )}
                </Label>

                <Controller
                  rules={{
                    required: {
                      value: watch('status')?.value === 'ACTIVE' ? true : false,
                      message: 'Must Select a category!',
                    },
                  }}
                  name="categoryId"
                  as={Select}
                  options={getOptions({
                    data: categories?.getCategories,
                  })}
                  placeholder="Category"
                  control={control}
                  styles={{ ...customSelectStyleNew(theme) }}
                />

                <small className="text-danger">
                  {errors?.categoryId?.message}
                </small>
              </FormGroup>
            </Col>

            <Col
              xl="4"
              lg="4"
              md="6"
              sm="12"
              xs="12"
              className="dollar-addon-btn"
            >
              <FormGroup>
                <Label>
                  Advertise Salary Min
                  {watch('status')?.value === 'ACTIVE' && (
                    <span className="text-danger">*</span>
                  )}
                </Label>

                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    autoComplete="off"
                    placeholder={salaryMinimumRange?.toString()}
                    name="salaryMin"
                    type="text"
                    onChange={(e) => {
                      moneyFieldHandler(
                        e?.target?.value,
                        setValue,
                        'salaryMin'
                      );
                    }}
                    innerRef={register({
                      required: {
                        value:
                          watch('status')?.value === 'ACTIVE' ? true : false,
                        message: 'Min Salary amount is required!',
                      },
                    })}
                  // errMsg={errors?.salaryMin?.message}
                  />
                </InputGroup>
                <small className="text-danger">
                  {errors?.salaryMin?.message}
                </small>
              </FormGroup>
            </Col>

            <Col
              xl="4"
              lg="4"
              md="6"
              sm="12"
              xs="12"
              className="dollar-addon-btn"
            >
              <FormGroup>
                <Label>
                  Advertise Salary Max
                  {watch('status')?.value === 'ACTIVE' && (
                    <span className="text-danger">*</span>
                  )}
                </Label>

                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Input
                    placeholder={salaryMaximumRange?.toString()}
                    name="salaryMax"
                    onChange={(e) => {
                      moneyFieldHandler(
                        e?.target?.value,
                        setValue,
                        'salaryMax'
                      );
                    }}
                    type="text"
                    innerRef={register({
                      required: {
                        value:
                          watch('status')?.value === 'ACTIVE' ? true : false,
                        message: 'Max Salary amount is required!',
                      },
                    })}
                  // errMsg={errors?.salaryMax?.message}
                  />
                </InputGroup>
                <small className="text-danger">
                  {errors?.salaryMax?.message}
                </small>
              </FormGroup>
            </Col>

            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>
                  Education
                  {watch('status')?.value === 'ACTIVE' && (
                    <span className="text-danger">*</span>
                  )}
                </Label>

                <Controller
                  name="degreeId"
                  rules={{
                    required: {
                      value: watch('status')?.value === 'ACTIVE' ? true : false,
                      message: 'Must Select an Education!',
                    },
                  }}
                  as={Select}
                  options={getOptions({
                    data: degrees?.getDegrees,
                  })}
                  placeholder="Education"
                  control={control}
                  styles={{ ...customSelectStyleNew(theme) }}
                />

                <small className="text-danger">
                  {errors?.degreeId?.message}
                </small>
              </FormGroup>
            </Col>

            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>
                  Must To Have Skills
                  {watch('status')?.value === 'ACTIVE' && (
                    <span className="text-danger">*</span>
                  )}
                </Label>

                <Controller
                  name="mustSkills"
                  rules={{
                    required: {
                      value: watch('mustSkills')?.length
                        ? false
                        : true && watch('status')?.value === 'ACTIVE'
                          ? true
                          : false,
                      message: 'Must Select Must to have skills!',
                    },
                  }}
                  render={({ onChange, name, ref }) => (
                    <CreatableSelect
                      isClearable={false}
                      multiValueRemove={true}
                      onChange={(value) => {
                        if (value?.length) {
                          setValue('mustSkills', value);
                          filterSkills(value);
                          onChange(value);
                          return;
                        }
                        setValue('mustSkills', null);
                      }}
                      name={name}
                      ref={ref}
                      isMulti
                      options={skillsList}
                      value={getValues().mustSkills}
                      placeholder="Must Have Skills"
                      styles={{ ...customSelectStyleNew(theme) }}
                    />
                  )}
                  control={control}
                  styles={customStyles}
                />

                <small className="text-danger">
                  {errors?.mustSkills?.message}
                </small>
              </FormGroup>
            </Col>

            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>Nice To Have Skills</Label>
                <Controller
                  name="niceSkills"
                  render={(props) => (
                    <CreatableSelect
                      isClearable={false}
                      onChange={(value) => {
                        setValue('niceSkills', value);
                        filterSkills(value);
                      }}
                      isMulti
                      options={skillsList}
                      value={getValues().niceSkills}
                      placeholder="Nice To Have Skills"
                      styles={{ ...customSelectStyleNew(theme) }}
                    />
                  )}
                  control={control}
                  styles={customStyles}
                />
              </FormGroup>
            </Col>

            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Min. Experience in Years"
                type="text"
                inputid="experienceMin"
                placeholder="Min Experience in Years"
                step="1"
                min="0"
                inputRef={register({
                  required: { value: false },
                  pattern: {
                    value: /^\d*(\.\d+)?$/,
                    message: 'Min Experience must be a number',
                  },
                })}
                errMsg={errors?.experienceMin?.message}
              />
            </Col>

            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Max. Experience in Years"
                type="text"
                inputid="experienceMax"
                placeholder="Max Experience in Years"
                step="1"
                min="0"
                inputRef={register({
                  required: {
                    value: false,
                    message: 'Max Experience must must be selected!',
                  },
                  pattern: {
                    value: /^\d*(\.\d+)?$/,
                    message: 'Max Experience must be a number',
                  },
                })}
                errMsg={errors?.experienceMax?.message}
              />
            </Col>

            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <FormGroup>
                <Label>
                  Workplace Type
                </Label>
                <Controller
                  name="workplaceTypes"
                  defaultValue={{ label: 'On-site', value: 'ONSITE' }}
                  placeHolder="Select Workplace Type"
                  render={() => (
                    <Select
                      options={workplaceTypes}
                      value={getValues()?.workplaceTypes}
                      onChange={(object) => {
                        setValue('workplaceTypes', object);
                      }}
                      styles={{ ...customSelectStyleNew(theme) }}
                    />
                  )}
                  control={control}
                  styles={{ ...customSelectStyleNew(theme) }}
                />
              </FormGroup>
            </Col>

            <Col md="12">
              <div className="d-flex justify-content-between mb-1 flex-wrap">
                <div className="d-flex align-items-center">
                  <Label id="" className="m-0">
                    Advertised Job Description
                    {watch('status')?.value === 'ACTIVE' && (
                      <span className="text-danger">*</span>
                    )}
                  </Label>
                  <div id="infoToolTip" className="ml-2 p-0">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                        fill="#33475B"
                      />
                      <path
                        d="M8.93001 6.588L6.64001 6.875L6.55801 7.255L7.00801 7.338C7.30201 7.408 7.36001 7.514 7.29601 7.807L6.55801 11.275C6.36401 12.172 6.66301 12.594 7.36601 12.594C7.91101 12.594 8.54401 12.342 8.83101 11.996L8.91901 11.58C8.71901 11.756 8.42701 11.826 8.23301 11.826C7.95801 11.826 7.85801 11.633 7.92901 11.293L8.93001 6.588ZM9.00001 4.5C9.00001 4.76522 8.89466 5.01957 8.70712 5.20711C8.51958 5.39464 8.26523 5.5 8.00001 5.5C7.7348 5.5 7.48044 5.39464 7.29291 5.20711C7.10537 5.01957 7.00001 4.76522 7.00001 4.5C7.00001 4.23478 7.10537 3.98043 7.29291 3.79289C7.48044 3.60536 7.7348 3.5 8.00001 3.5C8.26523 3.5 8.51958 3.60536 8.70712 3.79289C8.89466 3.98043 9.00001 4.23478 9.00001 4.5Z"
                        fill="#33475B"
                      />
                    </svg>
                  </div>
                </div>
                <Button
                  className="bg-transparent text-info border-0 p-0 for-link-theme-color"
                  style={{ marginTop: 'auto' }}
                  // onClick={() => {
                  //   setAdvertisedJobDescription(
                  //     getValues()?.advertisedJobDescription
                  //   );
                  //   advertisedJobDescriptionToogle();
                  // }}
                  onClick={() => handleJobDescription()}
                >
                  <small className="for-link-theme-color">
                    View Advertised Job Description
                  </small>
                </Button>
              </div>

              <Tooltip
                innerClassName="p-3 for-link-theme-color"
                style={{ maxWidth: '200rem', background: '#ffffff' }}
                isOpen={tooltipOpen}
                target="infoToolTip"
                toggle={ToolTipToggle}
              >
                Use [JOBLOCATION] Key to dynamically put the most recent job
                Advertised location in the description.
              </Tooltip>

              <Controller
                control={control}
                name="advertisedJobDescription"
                rules={{
                  required: {
                    value: watch('status')?.value === 'ACTIVE' ? true : false,
                    message: 'Advertised Job Description must be selected!',
                  },
                }}
                render={({ onChange, ref, value = '' }) => (
                  <div className="editor-height-job-order">
                    <ReactQuill
                      onChange={(value) => {
                        onChange(value);
                        setAdvertisedJobDescription(value);
                      }}
                      value={value}
                      ref={ref}
                    />
                  </div>
                )}
              />
              <small className="text-danger pt-2">
                {errors?.advertisedJobDescription?.message}
              </small>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        isOpen={advertisedJobDescriptionModal}
        toggle={advertisedJobDescriptionToggle}
        style={{ maxWidth: 850 }}
        id="noteModal"
        className="revampDialog"
      >
        <ModalHeader toggle={advertisedJobDescriptionToggle}>
          View Advertised Job Description
        </ModalHeader>
        <ModalBody style={{ height: 465 }}>
          <div
            className="for-word-break"
            dangerouslySetInnerHTML={createMarkup(advertisedJobDescription)}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            buttonText="Close"
            buttonColor="default"
            buttonType="button"
            buttonClick={advertisedJobDescriptionToggle}
            className="btn-primary"
          />
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default JobDetails;
