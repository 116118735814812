import { useMutation } from "@apollo/client";
import { FC, useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from 'react-select';
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { DrawerHeader, Sidedrawer } from "../../components/layout/Sidedrawer";
import { customSelectStyleNew } from "../../components/styled/customSelect";
import { ADD, SAVE } from "../../constants";
import { AppContext } from "../../context";
import { DrawerInvoiceStatusProps } from "../../interfaces";
import { formatDateUSWithMoment } from "../../utils/commonFn";
import { INVOICE_SENT, PAYMENT_RECEIVED, REMINDER_SENT, invoiceStatus, paymentType } from "../../utils/constant";
import { formatValue } from "../../utils/helper";
import { ADD_PAYMENT_HISTORY_RECORD, UPDATE_PAYMENT_HISTORY_RECORD } from "../candidate-pipeline/placements/gql";

type FormData = {
  paymentType: string;
  reminderDays?: string;
  paymentAt: string;
  paidPaymentType: string;
  note: string;
  invoiceNumber: string
};

const InvoiceStatusDrawer: FC<DrawerInvoiceStatusProps> = ({ toggle,
  refetchData, placementId, isOpen, isEditButton, editableRecord, invoiceNumber }) => {

  const { theme } = useContext(AppContext);
  const [reminderDays, setReminderDays] = useState<string>()
  const [status, setStatus] = useState<string>()
  const [date, setDate] = useState<string | Date>(new Date().toISOString())
  const { control, handleSubmit, setValue, getValues, reset, errors } = useForm<FormData>();

  //apollo mutations
  const [updateHistory, { loading: updateLoading }] = useMutation(UPDATE_PAYMENT_HISTORY_RECORD)
  const [createHistory, { loading: createLoading }] = useMutation(ADD_PAYMENT_HISTORY_RECORD)

  useEffect(() => {
    if (isEditButton) {
      setValue("paymentAt", editableRecord!.paymentAt.slice(0, 10));
      setValue("note", editableRecord!.note);
      setValue("paymentType", editableRecord!.paymentType);
      setValue("reminderDays", editableRecord!.reminderDays);
      editableRecord!.placement!.isPaid && setValue("paidPaymentType", editableRecord!.placement!.paymentType)
      setReminderDays(editableRecord!.reminderDays)
      setStatus(editableRecord!.paymentType)
      setDate(editableRecord!.paymentAt)
    } else if (isOpen) {
      reset({
        paymentType: "",
        paymentAt: new Date().toISOString().substring(0, 10),
        note: "",
      });
      setReminderDays("")
      setStatus("")
      setDate(new Date().toISOString())
    }
  }, [editableRecord, isOpen, isEditButton, reset, setValue]);

  useEffect(() => {
    if (status === REMINDER_SENT) {
      setValue("note", `${reminderDays || "-- "} days aged invoice reminder sent on ${formatDateUSWithMoment(date)}`);
    }
    else if (status === PAYMENT_RECEIVED) {
      setValue("note", `Payment received on ${formatDateUSWithMoment(date)}`);
    }
    else if (status === INVOICE_SENT) {
      !getValues()?.invoiceNumber && setValue("invoiceNumber", invoiceNumber)
      setValue("note", `Invoice Sent on ${formatDateUSWithMoment(date)}`);
    }
  }, [reminderDays, setValue, status, getValues, invoiceNumber, date])

  const update = async (data: FormData) => {
    try {
      const { reminderDays, paymentAt, ...rest } = data
      const response = await updateHistory({
        variables: {
          updateHistoryInput: {
            placementId,
            paymentAt: new Date(paymentAt),
            id: editableRecord?.id,
            reminderDays: parseFloat(reminderDays!),
            ...rest,
          }
        }
      })
      if (response?.data?.updatePlacementPaymentHistory?.status === 200) {
        toast.success("Updated successfully")
        refetchData && refetchData()
        toggle()
      }
    }
    catch {
      toast.error("Error while updating record")
    }
  }

  const create = async (data: FormData) => {
    try {
      const { reminderDays, paymentAt, ...rest } = data
      const response = await createHistory({
        variables: {
          createHistoryInput: {
            placementId,
            paymentAt: new Date(paymentAt),
            reminderDays: parseFloat(reminderDays!),
            ...rest,
          }
        }
      })

      if (response?.data?.createPlacementPaymentHistory?.status === 201) {
        toast.success("Created successfully")
        refetchData && refetchData()
        toggle()
      }
    }
    catch (error) {
      toast.error("Error while creating record")
    }
  }
  const onSubmit: SubmitHandler<FormData> = (data) => {
    editableRecord ? update(data) : create(data)
  };

  return (
    <>
      <Sidedrawer toggle={toggle} isOpen={isOpen} >
        <div className="mb-4">
          <DrawerHeader nextButtonView={false} prevClick={() => {
            toggle();
          }} heading={"Invoice Status"} />
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs="12" className="pt-4 my-3">
              <Label>Status <span className="text-danger">*</span></Label>
              <Controller
                control={control}
                name="paymentType"
                rules={{
                  required: {
                    value: true,
                    message: 'Status should be selected!',
                  },
                }}
                render={
                  ({ onChange, ref }) => (
                    <Select
                      placeholder="Status"
                      name="paymentType"
                      options={invoiceStatus}
                      value={getValues().paymentType ?
                        { value: getValues().paymentType, label: formatValue(getValues().paymentType!) } : null
                      }
                      onChange={(item) => {
                        setStatus(item!.value)
                        onChange(item!.value)
                      }}
                      styles={{ ...customSelectStyleNew(theme) }}
                    />)}
              />
              <small className="text-danger">
                {errors?.paymentType?.message}
              </small>
            </Col>

            <Col xs="12" className={status === INVOICE_SENT ? "nick-name my-3" : "my-0"}>
              {status === INVOICE_SENT && (
                <Label>Invoice Number <span className="text-danger">*</span></Label>
              )}
              <Controller
                name="invoiceNumber"
                control={control}
                rules={{
                  required: {
                    value: status === INVOICE_SENT,
                    message: 'Invoice Number should be selected!'
                  }
                }}
                render={(field) => (
                  <Input
                    {...field}
                    type="text"
                    hidden={status !== INVOICE_SENT}
                    placeholder="Invoice Number"
                    onChange={(e) => {
                      setValue("invoiceNumber", e.target.value.replace(/\D/g, '').slice(0, 8))
                    }}
                  />
                )}
              />
              <small className="text-danger">
                {status === INVOICE_SENT && errors?.invoiceNumber?.message}
              </small>
            </Col>

            <Col xs="12" className={status === REMINDER_SENT ? "nick-name my-3" : "my-0"}>
              {status === REMINDER_SENT && (
                <Label>Reminder Days <span className="text-danger">*</span></Label>
              )}
              <Controller
                name="reminderDays"
                control={control}
                rules={{
                  required: {
                    value: status === REMINDER_SENT,
                    message: 'Reminder days should be selected!'
                  }
                }}
                render={(field) => (
                  <Input
                    {...field}
                    type="text"
                    hidden={status !== REMINDER_SENT}
                    placeholder="Reminder Days"
                    onChange={(e) => {
                      setReminderDays(e.target.value.replace(/\D/g, '').slice(0, 3))
                      setValue("reminderDays", e.target.value.replace(/\D/g, '').slice(0, 3))
                    }}
                  />
                )}
              />
              <small className="text-danger">
                {status === REMINDER_SENT && errors?.reminderDays?.message}
              </small>
            </Col>

            <Col xs="12" className={status === PAYMENT_RECEIVED ? "nick-name my-3" : "my-0"}>
              <FormGroup hidden={status !== PAYMENT_RECEIVED}>
                <Label>
                  Payment Type <span className="text-danger">*</span>
                </Label>
                <Controller
                  name="paidPaymentType"
                  rules={{
                    required: {
                      value: status === PAYMENT_RECEIVED,
                      message: 'Payment type must be selected!',
                    },
                  }}
                  placeHolder="Select paymentType Type"
                  control={control}
                  render={({ onChange, ref }) => (
                    <Select
                      options={paymentType}
                      value={getValues().paidPaymentType ?
                        {
                          value: getValues().paidPaymentType,
                          label: getValues().paidPaymentType!.toLocaleLowerCase()
                        } : null
                      }
                      onChange={(item) => {
                        onChange(item!.value)
                      }}
                      styles={{ ...customSelectStyleNew(theme) }}
                    />
                  )}
                />

                <small className="text-danger">
                  {errors?.paidPaymentType?.message}
                </small>
              </FormGroup>
            </Col>

            <Col className="my-3">
              <Label>Date <span className="text-danger">*</span></Label>
              <Controller
                name="paymentAt"
                rules={{
                  required: {
                    value: true,
                    message: 'Date should be selected!'
                  }
                }}
                control={control}
                render={(field) => (
                  <Input
                    {...field}
                    type="date"
                    defaultValue={new Date().toISOString().substring(0, 10)}
                    onChange={(e) => {
                      setDate(new Date(e.target.value).toISOString())
                      setValue("paymentAt", e.target.value)
                    }}
                    max={new Date().toISOString().substring(0, 10)}
                  />
                )}
              />
              <small className="text-danger">
                {errors?.paymentAt?.message}
              </small>
            </Col>

            <Col xs="12" className="nick-name my-3">
              <Label>Notes</Label>
              <Controller
                name="note"
                control={control}
                render={(field) => (
                  <Input
                    {...field}
                    disabled={true}
                    type="text"
                    value={field.value}
                    placeholder="Notes"
                    onChange={(e) => {
                      setValue("note", e.target.value)
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          <div className="d-flex">
            <button
              type="submit"
              disabled={createLoading || updateLoading}
              className="buttonGenericStyle filledButton ml-auto mt-3" >
              {editableRecord ? SAVE : ADD}
              {(createLoading || updateLoading) &&
                <Spinner size="sm"></Spinner>}
            </button>
          </div>
        </Form>
      </Sidedrawer >
    </>
  );
};

export default InvoiceStatusDrawer;
