import { useCallback, useContext, useEffect, useState } from 'react';
// packages block
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
// components block
import SEARCH_IMG from '../../assets/images/search.svg';
import GoBack from '../../components/GoBack';
import CandidateSearchFilters from './candidate-components/CandidateFilters';
import CandidateSearchList from './candidate-components/CandidateSearchList';
import { ALL_CANDIDATES_ADVANCE_SEARCH } from './gql';
// interfaces, graphql, constants block
import { useLazyQuery } from '@apollo/client';
import { State } from 'country-state-city';
import Loader from '../../components/layout/Loader';
import { CANDIDATE_SEARCH, SEARCH_GENERIC } from '../../constants';
import { AppContext } from '../../context';
import { SelectorOptionType } from '../../interfaces';
import { countriesIsoCodeDto } from '../../utils/constant';

const CandidateSearch = () => {
  // states
  const [searchText, setSearchText] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [selectedState, setState] = useState<SelectorOptionType>({ label: '', value: '' });
  const [radius, setRadius] = useState<SelectorOptionType>({ label: '', value: '' });
  const [stateList, setStateList] = useState<{ label: string; value: string }[]>();
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { setContextSkillsESAggs, skillsESAggs } = useContext(AppContext);
  const [searchCall, setSearchCall] = useState<boolean>(false);
  // api calls
  const [_getCandidatesList, { loading, data }] = useLazyQuery(
    ALL_CANDIDATES_ADVANCE_SEARCH
  );
  // component lifecycle methods
  useEffect(() => {
    let statesOfSelectedCountry = State.getStatesOfCountry(
      countriesIsoCodeDto[selectedCountry]
    );

    let statesOfSelectedCountryInLabelValuePair: { label: string; value: string }[] = statesOfSelectedCountry.map(
      (v) => {
        return {
          label: v.name,
          value: v.isoCode,
        };
      }
    );
    setStateList(statesOfSelectedCountryInLabelValuePair);
    setState({ label: '', value: '' });
    setCity('');
    setRadius({ label: '', value: '', })
  }, [selectedCountry]);

  const getCandidatesList = useCallback(() => {
    _getCandidatesList({
      variables: {
        limit,
        page: currentPage,
        searchText,
        city: city,
        state: selectedState.value,
        zipCode,
        radius: radius.value,
        skills: skillsESAggs,
        country: selectedCountry
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_getCandidatesList, city, currentPage, limit, radius.value, selectedCountry, selectedState.value, skillsESAggs, zipCode, searchCall]);

  useEffect(() => {
    getCandidatesList();
  }, [limit, currentPage, getCandidatesList, searchCall]);

  const clearFilters = () => {
    setState({ label: '', value: '' });
    setCity('');
    setRadius({ label: '', value: '' });
    setSearchText('');
    setZipCode('');
    setCurrentPage(1);
    setLimit(10);
    // setSelectedSkills([]);
    setContextSkillsESAggs([]);
  };

  const handleSkills = (event) => {
    let skills = skillsESAggs?.length ? skillsESAggs : [];
    if (event?.target?.checked) {
      let val = event?.target?.value;
      if (!skills.some((item) => item === val)) {
        skills.push(val);
      }
    } else {
      let val = event?.target?.value;
      skills = skills.filter((item) => item !== val);
    }

    setContextSkillsESAggs([...skills]);
  };

  useEffect(() => {
    return () => {
      setContextSkillsESAggs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Event Handlers to trigger the search API call when the user presses Enter
  const handleSearchEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchCall(!searchCall);
      setCurrentPage(1);
    }
  };

  const handleSearchEnterUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchCall(!searchCall);
    }
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack />

      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">{CANDIDATE_SEARCH}</h5>
      </div>

      <div className="candidate-search-wrap">
        <div className="bg-white dark-card">
          <div className="candidate-search-sfilter">
            <div className="search-filter d-block searchFilterResponsive p-3">
              <div className="serach-for-candidate mr-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    type="text"
                    value={searchText}
                    placeholder={SEARCH_GENERIC}
                    className="search-by-company"
                    onKeyDown={handleSearchEnterDown}
                    onKeyUp={handleSearchEnterUp}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
          </div>

          <div className="candidate-search-content">
            <Row>
              <Col md={6} lg={4} xl={3}>
                <CandidateSearchFilters
                  selectedState={selectedState}
                  city={city}
                  zipCode={zipCode}
                  setCity={setCity}
                  setState={setState}
                  setZipCode={setZipCode}
                  radius={radius}
                  setRadius={setRadius}
                  getCandidatesList={getCandidatesList}
                  clearFilters={clearFilters}
                  aggregateResult={data?.allCandidatesAdvanceSearch?.aggsResult}
                  handleSkills={handleSkills}
                  setCurrentPage={setCurrentPage}
                  stateList={stateList}
                  setStateList={setStateList}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  searchCall={searchCall}
                  setSearchCall={setSearchCall}
                  handleSearchEnterDown={handleSearchEnterDown}
                  handleSearchEnterUp={handleSearchEnterUp}
                />
              </Col>
              <Col md={6} lg={8} xl={9}>
                {!loading ? (
                  <CandidateSearchList
                    data={data}
                    limit={limit}
                    setLimit={setLimit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ) : (
                  <Loader />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateSearch;
