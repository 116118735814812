import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { GET_NOTES } from "../Pages/company/gql";
import { compactObject, createMarkup, formatDateUS } from "../utils/commonFn";
import { PAGE_LIMIT } from "../utils/constant";
import AddNotesModal from "./AddNotesModal";
import CustomButton from "./layout/CustomButton";
import InputField from "./layout/InputField";
import Loader from "./layout/Loader";
import NoDataFound from "./layout/NoDataFound";
import Pagination from "./layout/Pagination";

const ApplicantNotes = ({ applicantId, type, candidateId, ...props }: any) => {
  const history = useHistory();
  const page =
    new URLSearchParams(history?.location?.search).get("currentPage") || "1";
  const [limit, setLimit] = useState(3);
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [notesModal, setNotesModal] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const [noteContent, setNoteContent] = useState("");

  const notesToggle = () => setNotesModal(!notesModal);
  const toggleActivityNotes = () => setActivityNotes(!activityNotes);

  const [getNotes, { data: notes, loading: loadingNotes }] =
    useLazyQuery(GET_NOTES);

  const _getNotes = () => {
    getNotes({
      variables: {
        id: applicantId,
        notePayload: { type },
        page: 1,
        limit: 3,
      },
    });
  };

  useEffect(() => {
    if (applicantId) {
      getNotes({
        variables: {
          id: applicantId,
          notePayload: { type },
          limit,
          page: currentPage,
        },
      });
    }

    // eslint-disable-next-line
  }, [applicantId, limit, currentPage]);

  return (
    <div>
      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={applicantId}
        reFetch={() => _getNotes()}
        type={type}
        editData={noteData}
        resetField={setNoteData}
      />

      {type !== "Submission" && (
        <div className="jobOrderRevamp">
          <div className="table-responsive">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="m-0">{type} Notes </h5>

                <Link
                  target="_blank"
                  to={{
                    pathname: `/notes-es?${new URLSearchParams(
                      compactObject(props?.extraProps)
                    ).toString()}`,
                  }}
                  className="font-weight-bold mr-2"
                  style={{ color: "#96a1ac" }}
                >
                  <button
                    className="buttonGenericStyle filledButton m-0"
                    type="button"
                  >
                    {type} Notes Search
                  </button>
                </Link>
              </div>

              <div className="mt-2">
                <div className="user-basic-form">
                  {loadingNotes ? (
                    <Loader loaderClass="sm-height " />
                  ) : (
                    <div>
                      {notes?.getNotes?.notes &&
                        notes?.getNotes?.notes?.map((item: any) => (
                          <div className="notes-flex">
                            <div>
                              <small className="text-muted">
                                {item?.category}
                              </small>
                              <div className="d-flex">
                                <div
                                  className="line-clamp"
                                  dangerouslySetInnerHTML={createMarkup(
                                    item?.content
                                  )}
                                />
                              </div>
                              {item?.content?.length > 50 && (
                                <Button
                                  className="bg-transparent text-info border-0 p-0 for-link-theme-color"
                                  style={{ marginTop: "auto" }}
                                  onClick={() => {
                                    setNoteContent(item?.content);
                                    notesToggle();
                                  }}
                                >
                                  <small className="for-link-theme-color">
                                    View Full Note
                                  </small>
                                </Button>
                              )}
                              <div className="added-by">
                                <small>
                                  {item.createdAt &&
                                    formatDateUS(item.createdAt)}{" "}
                                </small>
                                <small className="mr-2">
                                  {item.createdAt &&
                                    new Date(
                                      item.createdAt
                                    ).toLocaleTimeString()}
                                </small>
                                <small>{item?.user?.fullName}</small>
                              </div>
                            </div>
                          </div>
                        ))}

                      {!loadingNotes && !notes?.getNotes?.notes?.length && (
                        <NoDataFound text="Notes Not Found!" />
                      )}
                    </div>
                  )}
                </div>

                {notes?.getNotes?.notes?.length ? (
                  <div className="users-pagination">
                    <Pagination
                      onPageChange={(pageClicked: number) => {
                        setCurrentPage(pageClicked);
                      }}
                      pageCount={Math.ceil(notes?.getNotes?.count / limit)}
                      currentPage={currentPage}
                    />

                    <div className="d-flex align-items-center">
                      <div className="pagination-display paginationItemsSelect">
                        <p className="m-0">
                          {(currentPage - 1) * limit + 1} to{" "}
                          {Math.min(
                            currentPage * limit,
                            notes?.getNotes?.count
                          )}{" "}
                          of {notes?.getNotes?.count}
                        </p>

                        <div className="divider"></div>

                        <label>Display</label>

                        <InputField
                          label=""
                          inputtype="select"
                          selectItems={PAGE_LIMIT.filter(
                            (item) => item.value !== limit
                          )}
                          inputid="limit"
                          placeholder={limit.toString()}
                          inputMethod={(value: string) => {
                            setCurrentPage(1);
                            setLimit(parseInt(value));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal isOpen={notesModal} toggle={notesToggle} id="noteModal">
        <ModalHeader toggle={notesToggle}>View Note</ModalHeader>
        <ModalBody>
          <div
            className="for-word-break"
            dangerouslySetInnerHTML={createMarkup(noteContent)}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            buttonText="Close"
            buttonColor="default"
            buttonType="button"
            buttonClick={notesToggle}
            className="btn-primary"
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ApplicantNotes;
