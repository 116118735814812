import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Alert,
} from 'reactstrap';
import ActionLogsAndNotes from '../../components/ActionLogsAndNotes';
import GoBack from '../../components/GoBack';
import Loader from '../../components/layout/Loader';
import { checkUserFeatureAccess, compactObject, createMarkup } from '../../utils/commonFn';
import {
  FETCH_TEAM_AND_MEMBERS,
  FETCH_TEAM_AND_MEMBERS_MANUAL_USER,
  GET_JOB_ORDER,
  FINDING_PARALLEL_TEAM_MEMBERS,
  GET_SINGLE_ADVERTISED_LOCATION,
  GET_JOB_ORDER_MAIN_CONTACT,
  CREATE_JOB_ORDER,
} from './gql';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { AppContext } from '../../context';
import {
  GET_ALL_COMPANY_OWNER_SHAREE_WITH_TYPE,
  GET_NOTES,
} from '../company/gql';
import CustomButton from '../../components/layout/CustomButton';
import RecruiterAssigned from './job-order-components/RecruiterAssigned';
import { FETCHING_USERS_WITHOUT_ROLES } from '../admin/gql';
import AdvertiseLocationJobOrder from './job-order-components/AdvertiseLocaitonJobOrder';
import AddNotesModal from '../../components/AddNotesModal';
import { IComingState } from '../agreements/interfaces';
import { chatGptJobOrderTemplates, getTemplateByModule, jobDescriptionHandler, moneyFormatter, pushInHistory, replacePlaceholderWithResume } from '../../utils/helper';
import classnames from 'classnames';

import READ_MORE_ARROW from '../../assets/images/read-arrow.svg';
import INFO_ICON_1 from '../../assets/images/info-icon-1.svg';
import INFO_ICON_6 from '../../assets/images/location-icon.svg';
import TITLE_ICON from '../../assets/images/card-title.svg';
import JobApplicantTab from './job-order-components/JobApplicantTab';
import JobOrderClientDocument from './job-order-components/JobOrderClientDocument';
import Attachments from '../company/company-components/Attachments';
import SubmissionHistoryCandidate from '../candidate/candidate-components/SubmissionHistoryCandidate';
import { createClonedJobOrderPayload } from '../../utils/createJobOrderData';
import { toast } from 'react-toastify';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import AsyncCompanyDropdown from '../../components/AsyncCompanySelect';
import { FETCH_CONTACT_HISTORY_DETAIL } from '../contacts/gql';
import { COMPANY_DETAILS_ROUTE, CONTACT_DETAIL_ROUTE, JobOrderStatus, UPDATE_JOB_ORDER_ROUTE, VIEW_AGREEMENT_ROUTE, VIEW_JOB_ORDER_ROUTE } from '../../utils/constant';
import ChatgptButton from '../../common/chatGpt/ChatgptButton';
import ResumeDrawer from '../../common/chatGpt/ResumeDrawer';
import useHTTPGPT from "../../HTTP_Request/useHTTPGPT.js";
import { v4 as uuidv4 } from "uuid";
import { ALL_MODAL_ACCCESS_SETTINGS } from '../moduleAccess-settings/gql';
import { ALL_CHATGPT_TEMPLATES } from '../chatGPT-templates/gql';
import JobListNotes from '../jobListNotes';

const ViewJobOrder = ({ match }: any) => {
  const { data: templateData } = useQuery(ALL_CHATGPT_TEMPLATES);
  const { data: settingsData } = useQuery(ALL_MODAL_ACCCESS_SETTINGS);
  const location = useLocation<IComingState>();
  const comingState: any = location.state;
  const history = useHistory();
  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '1';
  const [activeTab, setActiveTab] = useState(activeParamTab);
  const [contactWarning, setContactWarning] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [promptString, setPromptString] = useState<number>(0);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [resumeDrawer, setResumeDrawer] = useState<boolean>(false);
  const [getJobOrder, { data, loading }] = useLazyQuery(GET_JOB_ORDER);
  const { user, userRoles, teachList } = useContext(AppContext);
  const [ownerOrShareeOfCompany, setOwnerOrShareeOfCompany] = useState('');
  const [_duplicateJobOrder, { loading: duplicating }] =
    useMutation(CREATE_JOB_ORDER);
  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [noteData, setNoteData] = useState(null);
  const [advertiseLocationList, setAdvertisedLocationList] = useState([]);

  const [advertisedJobDescriptionModal, setAdvertisedJobDescriptionModal] =
    useState(false);
  const [getAdvertisedLocations, { data: singleAdvertisedLocations }] =
    useLazyQuery(GET_SINGLE_ADVERTISED_LOCATION);
  const [advertisedJobDescription, setAdvertisedJobDescription] = useState('');

  const advertisedJobDescriptionToggle = () =>
    setAdvertisedJobDescriptionModal(!advertisedJobDescriptionModal);
  const [getOwnershipSharee, { data: ownershipSharee }] = useLazyQuery(
    GET_ALL_COMPANY_OWNER_SHAREE_WITH_TYPE
  );
  const [findingParallelTeamUsers, { data: parallelTeamUsers }] = useLazyQuery(
    FINDING_PARALLEL_TEAM_MEMBERS
  );


  const [getUsers, { data: users }] = useLazyQuery(
    FETCHING_USERS_WITHOUT_ROLES
  );

  const [fetchTeamMemberManualUser, { data: teamMembersManualUser }] =
    useLazyQuery(FETCH_TEAM_AND_MEMBERS_MANUAL_USER);

  const [fetchTeamMember, { data: teamMembers }] = useLazyQuery(
    FETCH_TEAM_AND_MEMBERS
  );

  const [getNotes] = useLazyQuery(GET_NOTES);

  const [getJobMainContactQuery, { data: jobMainContact }] = useLazyQuery(
    GET_JOB_ORDER_MAIN_CONTACT
  );
  const [getContactHistory, { data: contactHistory }] = useLazyQuery(
    FETCH_CONTACT_HISTORY_DETAIL
  );
  const _getNotes = () => {
    getNotes({
      variables: {
        id: data?.getJobOrder?.id,
        notePayload: { type: 'Job Order' },
        page: 1,
        limit: 3,
      },
    });
  };
  const promptMenu: string[] = ['3 To 5 Must Have Skills', '3 To 5 Good To Have Skills', '3 To 5 Interview Questions']

  //setting title
  useEffect(() => {
    document.title = `${data?.getJobOrder?.jobTitle || 'Job - RealREPP'
      } - Job - RealREPP`;
  }, [data?.getJobOrder]);

  useEffect(() => {
    let id = match.params.id;
    if (id) {
      getJobOrder({ variables: { id } });
    }
    getJobMainContactQuery({ variables: { id } });
    getUsers();

    // eslint-disable-next-line
  }, []);

  const handleResumeDrawer = (item) => {
    setPromptString(item)
    setResumeDrawer(!resumeDrawer);
  }
  useEffect(() => {
    fetchTeamMember();
    if (ownershipSharee) {
      const filterOwner = ownershipSharee?.getAllCompanyOwnerAndSharee.find(
        (item: any) => item?.ownerType === 'OWNER'
      );

      const filterCurrentUserAsOwner =
        ownershipSharee?.getAllCompanyOwnerAndSharee.find(
          (item: any) =>
            item?.ownerType === 'OWNER' && item?.owner?.id === user?.id
        );

      const filterCurrentUserAsSharee =
        ownershipSharee?.getAllCompanyOwnerAndSharee.find(
          (item: any) =>
            item?.ownerType === 'SHAREE' && item?.owner?.id === user?.id
        );

      if (filterCurrentUserAsOwner) {
        setOwnerOrShareeOfCompany('OWNER');
      } else if (filterCurrentUserAsSharee) {
        setOwnerOrShareeOfCompany('SHAREE');
      } else {
        setOwnerOrShareeOfCompany('NOT_OWNER_NOT_SHAREE');
      }

      if (filterOwner) {
        fetchTeamMemberManualUser({
          variables: { id: filterOwner?.owner?.id },
        });
      }
    }
    // eslint-disable-next-line
  }, [ownershipSharee]);

  useEffect(() => {
    const companyId = data?.getJobOrder?.company?.id;
    if (companyId) {
      getOwnershipSharee({
        variables: { id: companyId },
      });
    }

    // eslint-disable-next-line
  }, [data?.getJobOrder?.company?.id]);

  useEffect(() => {
    const companyId = data?.getJobOrder?.company?.id;
    if (companyId) {
      findingParallelTeamUsers({
        variables: { companyId },
      });
    }
    // eslint-disable-next-line
  }, [ownershipSharee?.getAllCompanyOwnerAndSharee?.length]);

  const checkEditPermission = () => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }
    if (ownershipSharee?.getAllCompanyOwnerAndSharee?.length) {
      const currentUser = ownershipSharee?.getAllCompanyOwnerAndSharee?.find(
        (item: any) => item?.owner?.id === user?.id
      );
      if (currentUser) {
        return true;
      }
    }

    return false;
  };

  const teamPermissionMethod = () => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }
    if (teamMembers && teamMembersManualUser) {
      const teamMatched =
        teamMembersManualUser?.widgetTeamMembersManualUser.find(
          (item: any) =>
            item?.team?.id === teamMembers?.widgetTeamMembers[0]?.team?.id
        );
      if (teamMatched) {
        return true;
      }
    } else if (teamMembers) {
      return true;
    } else {
      return false;
    }
  };

  const checkEditPermissionWithoutSharee = () => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }
    if (ownershipSharee?.getAllCompanyOwnerAndSharee?.length) {
      const currentUser = ownershipSharee?.getAllCompanyOwnerAndSharee?.find(
        (item: any) =>
          item?.owner?.id === user?.id && item?.ownerType === 'OWNER'
      );
      if (currentUser) return true;
    }
    return false;
  };

  const loc =
    `${singleAdvertisedLocations?.getSingleLocation?.city}` +
    ', ' +
    `${singleAdvertisedLocations?.getSingleLocation?.state}`;

  const toggleActivityNotes = () => setActivityNotes(!activityNotes);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  const handleJobDescription = () => {
    let desc = data?.getJobOrder?.advertisedJobDescription;
    if (advertiseLocationList?.length) {
      let cities = advertiseLocationList?.map((item: any) =>
        item.city.toLowerCase()
      );
      if (cities?.includes('san francisco')) {
        desc = jobDescriptionHandler(desc, true);
      } else {
        desc = jobDescriptionHandler(desc, false);
      }
    }
    // this is for JOBLOCAITON tag replacement with recent advertised location
    desc = desc?.replaceAll('[JOBLOCATION]', loc);
    setAdvertisedJobDescription(desc);
    advertisedJobDescriptionToggle();
  };

  // this function is used for the create duplicate job order
  const duplicateJobOrder = async () => {
    let values: any = { ...data?.getJobOrder };
    values.status = 'DRAFT';
    values.jobOrderId = data?.getJobOrder?.id;
    values.jobTitle = `(Copied of ${values?.jobTitle})`;
    values.companyId = selectedCompanyId;
    values.isHot = false;
    let jobOrderDetail = createClonedJobOrderPayload(values);
    jobOrderDetail.recruitingList = [];
    let res = await _duplicateJobOrder({
      variables: { CreateJobOrderInput: { ...compactObject(jobOrderDetail) } },
    });
    if (res?.data) {
      toast.success('Successfully Cloned!');
      setConfirmToggle(!confirmToggle);
      setSelectedCompanyId('');
      setCloneModal(!cloneModal);
      history.push(`/update-job-order/${res?.data?.createJobOrder?.id}`);
    }
  };
  useEffect(() => {
    if (data?.getJobOrder?.id) {
      getAdvertisedLocations({ variables: { id: data?.getJobOrder?.id } });
      changeContactHandler();
    }
    // eslint-disable-next-line
  }, [data]);

  const changeContactHandler = () => {
    let mainContact = data?.getJobOrder?.mainContact?.contact?.id;
    let billingContact = data?.getJobOrder?.billingContact?.contact?.id;
    let remoteContact = data?.getJobOrder?.remoteContact?.contact?.id;
    let allJobContact = [mainContact, billingContact, remoteContact];
    let companyContact: any = data?.getJobOrder?.company?.companyContacts?.map(
      (item) => item.id
    );

    let contactDontExist: any = [];
    for (let prvContact of allJobContact) {
      if (!companyContact?.includes(prvContact)) {
        contactDontExist.push(prvContact);
      }
    }

    if (contactDontExist?.length) {
      getContactHistory({ variables: { contactId: contactDontExist[0] } });
    } else {
      setContactWarning(false);
    }
  };

  useEffect(() => {
    if (contactHistory?.getContactHistoryDetail && !contactWarning) {
      setContactWarning(true);
    }
  }, [contactHistory?.getContactHistoryDetail, contactWarning]);

  const gptAPI = useHTTPGPT();
  const [messages, setMessages] = useState<{ content: string, id: string, role: string }[]>([]);

  useEffect(() => {
    if (data?.getJobOrder?.id) {
      const storedMessages = localStorage.getItem(`JobOrder: ${data?.getJobOrder?.id}-${promptString}`);
      if (storedMessages?.length) {
        setMessages(JSON.parse(storedMessages));
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptString]);

  useEffect(() => {
    if (!resumeDrawer) {
      setMessages([])
    }
  }, [resumeDrawer])

  useEffect(() => {
    if (data?.getJobOrder?.id && messages?.length) {
      localStorage.setItem(`JobOrder: ${data?.getJobOrder?.id}-${promptString}`, JSON.stringify(messages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);


  async function submitChat(content) {
    try {
      const message = {
        id: uuidv4(),
        role: "user",
        content: content,
      };

      let newMessages = [...messages, message];
      setMessages(newMessages);
      const response = await gptAPI.post(
        teachList.map((t) => ({ role: "system", content: t.content }))
          .concat(
            newMessages.slice(-8).map((message) => {
              return {
                role: message.role,
                content: message.content,
              };
            })
          )
      );
      newMessages = [...messages, message, response];
      setMessages(newMessages);
    } catch { }
  }

  const clearChat = () => {
    localStorage.removeItem(`JobOrder: ${data?.getJobOrder?.id}-${promptString}`)
    setMessages([]);
  }


  if (loading) {
    return <Loader />;
  }


  return (
    <div className="">
      <GoBack
        url={comingState?.backUrl ?? '/job-orders'}
        search={comingState?.searchParameters}
      />

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <div className="d-flex align-items-center ">
          <h5 className="m-0 mr-2">{data?.getJobOrder?.jobTitle || '--'}</h5>

          <Badge
            color={
              data?.getJobOrder?.status === 'ACTIVE'
                ? 'success'
                : data?.getJobOrder?.status === 'DRAFT'
                  ? 'info'
                  : data?.getJobOrder?.status === 'FILLED_BY_US' ||
                    data?.getJobOrder?.status === 'FILLED_BY_CLIENT'
                    ? 'secondary'
                    : data?.getJobOrder?.status === 'CANCELLED' ||
                      data?.getJobOrder?.status === 'LOST_TO_COMPETITOR' ||
                      data?.getJobOrder?.status === 'CLOSE'
                      ? 'danger'
                      : data?.getJobOrder?.status === 'HOLD' ||
                        data?.getJobOrder?.status === 'DUPLICATED_JOB'
                        ? 'warning'
                        : ''
            }
            className="text-capitalize"
          >
            {JobOrderStatus.find(({ value }) => value === data?.getJobOrder?.status)?.label}
          </Badge>
          {data?.isHotJob && (
            <div className="hotBadge d-flex align-items-center justify-content-center">
              Hot
            </div>
          )}
        </div>

        <div className="d-flex align-items-baseline flex-wrap">
          <button
            type="button"
            className="buttonGenericStyle filledButton mr-2"
            disabled={duplicating}
            onClick={() => setConfirmToggle(!confirmToggle)}
          >
            {duplicating && (
              <Spinner size="sm" className="mr-2" color="white" />
            )}{' '}
            Clone This Job Order
          </button>

          {checkEditPermission() && (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="actionsDropdown"
            >
              <DropdownToggle caret>Actions</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  tag={Link}
                  className="cursor-pointer"
                  onClick={() =>
                    pushInHistory(
                      `${VIEW_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`,
                      location.search
                    )
                  }
                  to={{
                    pathname: `${UPDATE_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`,
                    state: {
                      backUrl: `${VIEW_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`,
                    },
                  }}
                >
                  Edit
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>

      {contactWarning && (
        <Alert color="danger">
          Company contact{' '}
          <strong>
            {' '}
            {contactHistory?.getContactHistoryDetail?.contact?.fullName}
          </strong>{' '}
          is currently assigned to client{' '}
          <strong>
            {contactHistory?.getContactHistoryDetail?.newCompany?.name}
          </strong>
        </Alert>
      )}

      <div className="applicantDetail primaryHeaderSpacing">
        <div className="infoCardDivider">
          <img src={INFO_ICON_1} alt="company" width="17px" />

          {!!data?.getJobOrder?.company?.name ? (
            <label
              className="route-link"
              onClick={() => {
                history.push({
                  pathname: `${COMPANY_DETAILS_ROUTE}/${data?.getJobOrder?.company?.id}`,
                  state: {
                    backUrl: `${VIEW_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`,
                    searchParameters: location.search,
                  },
                });

                pushInHistory(
                  `${VIEW_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`,
                  location.search
                );
              }}
            >
              {data?.getJobOrder?.company?.name}
            </label>
          ) : (
            <label>--</label>
          )}
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <img src={INFO_ICON_6} alt="address" width="17px" />
          <label>
            {data?.getJobOrder?.companyLocation?.country === 'CA' ? (
              (data?.getJobOrder?.companyLocation?.city || '--') +
              ', ' +
              (data?.getJobOrder?.companyLocation?.state || '--') +
              ', ' +
              (data?.getJobOrder?.companyLocation?.country || '--')
            ) :
              ['US', null, undefined, ''].includes(
                data?.getJobOrder?.companyLocation?.country
              )
                ? (data?.getJobOrder?.companyLocation?.city || '--') +
                ', ' +
                (data?.getJobOrder?.companyLocation?.state || '--')
                : (data?.getJobOrder?.companyLocation?.city || '--') +
                ', ' +
                (data?.getJobOrder?.companyLocation?.country || '--')}
          </label>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <img src={TITLE_ICON} alt="type" width="17px" />
          <label>{data?.getJobOrder?.workType || '--'}</label>
        </div>
      </div>

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              About
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Activity
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              Advertised Location
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              Assigned Recruiters
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              Documents
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggleTab('6');
              }}
            >
              Job Applicants
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' })}
              onClick={() => {
                toggleTab('7');
              }}
            >
              Company Documents
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '8' })}
              onClick={() => {
                toggleTab('8');
              }}
            >
              Submission
            </NavLink>
          </NavItem>

          {checkUserFeatureAccess(user?.id, 'JOB_LIST', settingsData) &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '9' })}
                onClick={() => {
                  toggleTab('9');
                }}
              >
                Job List Notes
              </NavLink>
            </NavItem>}

        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane className="tab-pane-padding" tabId="1">
            <div className="jobOrderRevamp p-0">
              <Row>
                <Col xl="6" lg="6" md="6" sm="12">
                  <h5>Basic Info</h5>

                  <div>
                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Title</label>
                      <h6>{data?.getJobOrder?.jobTitle || '--'}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Advertised Title</label>
                      <h6>{data?.getJobOrder?.advertisedJobTitle || '--'}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Company</label>
                      <h6 className="">
                        {!!data?.getJobOrder?.company?.name ? (
                          <h6
                            className="route-link"
                            onClick={() => {
                              history.push({
                                pathname: `${COMPANY_DETAILS_ROUTE}/${data?.getJobOrder?.company?.id}`,
                                state: {
                                  backUrl: `${VIEW_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`,
                                },
                              });

                              pushInHistory(
                                `${VIEW_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`
                              );
                            }}
                          >
                            {data?.getJobOrder?.company?.name}
                          </h6>
                        ) : (
                          '--'
                        )}
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Job Location</label>
                      <h6>
                        <span>
                          {data?.getJobOrder?.companyLocation?.streetAddress}
                        </span>
                        <br />
                        <span>
                          {data?.getJobOrder?.companyLocation ? (
                            ['US', null, undefined, ''].includes(
                              data?.getJobOrder?.companyLocation?.country
                            )
                              ? `${data?.getJobOrder?.companyLocation?.city}, ${data?.getJobOrder?.companyLocation?.state}, ${data?.getJobOrder?.companyLocation?.zipCode}`
                              : `${data?.getJobOrder?.companyLocation?.city}, ${data?.getJobOrder?.companyLocation?.country}`) : '--'}
                        </span>
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Main Contact</label>
                      <h6>
                        <a
                          className="color-blue-text"
                          href={`${CONTACT_DETAIL_ROUTE}/${data?.getJobOrder?.mainContact?.contact?.id}`}
                        >
                          {data?.getJobOrder?.mainContact?.contact?.fullName ||
                            '--'}
                        </a>
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Billing Contact</label>
                      <h6>
                        <a
                          className="color-blue-text"
                          href={`${CONTACT_DETAIL_ROUTE}/${data?.getJobOrder?.billingContact?.contact?.id}`}
                        >
                          {data?.getJobOrder?.billingContact?.contact
                            ?.fullName || '--'}
                        </a>
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Active Agreements</label>
                      <h6>
                        {data?.getJobOrder?.agreement?.id ? (
                          <a
                            className="color-blue-text"
                            href={`${VIEW_AGREEMENT_ROUTE}/${data?.getJobOrder?.agreement?.id}`}
                          >
                            {data?.getJobOrder?.agreement?.nickName || '--'}
                          </a>
                        ) : (
                          '--'
                        )}
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Fee Type</label>
                      <h6>{data?.getJobOrder?.feeType || '--'}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Fee </label>

                      <h6>
                        {data?.getJobOrder?.fee &&
                          data?.getJobOrder?.fee > 0 ? (
                          <>
                            {data?.getJobOrder?.feeType === 'PERCENTAGE'
                              ? `${data?.getJobOrder?.fee} %`
                              : `${moneyFormatter(
                                data?.getJobOrder?.fee || 0
                              )}`}
                          </>
                        ) : (
                          <>{data?.getJobOrder?.fee || '--'}</>
                        )}
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>No. Of Positions</label>
                      <h6>{data?.getJobOrder?.positions || '--'}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Report to Contact</label>
                      <h6>
                        <a
                          className="color-blue-text"
                          href={`${CONTACT_DETAIL_ROUTE}/${data?.getJobOrder?.remoteContact?.contact?.id}`}
                        >
                          {data?.getJobOrder?.remoteContact?.contact
                            ?.fullName || '--'}
                        </a>
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Status</label>
                      <h6>{data?.getJobOrder?.status ? JobOrderStatus.find(({ value }) => value === data?.getJobOrder?.status)?.label : '--'}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Is Hot Job</label>
                      <h6>
                        {data?.getJobOrder?.isHotJob ? 'YES' : 'NO' || '--'}
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Estimated Fee</label>
                      <h6>
                        {moneyFormatter(data?.getJobOrder?.estimatedFee || 0)}
                      </h6>
                    </div>
                  </div>
                </Col>

                <Col xl="6" lg="6" md="6" sm="12" className="sectionDivider">
                  <div>
                    <h5>Job Details</h5>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Education</label>
                      <h6>{data?.getJobOrder?.degree?.name || '--'}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Must To Have Skills</label>
                      <div className="d-flex flex-wrap labelTextGrid">
                        {(data?.getJobOrder?.mustSkills?.length &&
                          data?.getJobOrder?.mustSkills?.map((v) => (
                            <span className="skillBadge">{v?.skill?.name}</span>
                          ))) ||
                          '--'}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Nice To Have Skills</label>
                      <div className="d-flex flex-wrap labelTextGrid">
                        {' '}
                        {(data?.getJobOrder?.niceSkills?.length &&
                          data?.getJobOrder?.niceSkills?.map((v) => (
                            <span className="skillBadge">{v?.skill?.name}</span>
                          ))) ||
                          '--'}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Workplace Type</label>
                      <h6>
                        {data?.getJobOrder?.workplaceTypes
                          ? data?.getJobOrder?.workplaceTypes
                          : '--'}
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Travel Percentage</label>
                      {data?.getJobOrder?.travelPercentage ? (
                        <h6>{`${data?.getJobOrder?.travelPercentage} %`}</h6>
                      ) : (
                        <h6>{'0 %'}</h6>
                      )}
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Rate Type</label>
                      <h6>{data?.getJobOrder?.rateType || '--'}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Work Type</label>
                      <h6>{data?.getJobOrder?.workType || '--'}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label> Experience</label>
                      <h6>
                        {data?.getJobOrder?.experienceMin || '--'} -{' '}
                        {data?.getJobOrder?.experienceMax || '--'} years
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                      <label>Category</label>
                      <h6>{data?.getJobOrder?.category?.name || '--'}</h6>
                    </div>
                    {!!data?.getJobOrder?.advertisedJobDescription && (
                      <>
                        {
                          checkUserFeatureAccess(user?.id, 'CHATGPT', settingsData) && (
                            <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                              <ChatgptButton
                                title="Actions"
                                menu={promptMenu}
                                onClick={handleResumeDrawer}
                              />
                            </div>
                          )
                        }


                      </>
                    )}
                  </div>
                </Col>
                <Col xl="6" lg="6" md="12" sm="12" className="advertiseColumn">
                  <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                    <label>Advertised Salary Min</label>
                    <h6>
                      {data?.getJobOrder?.rateType === 'ANNUAL' ? (
                        <>{moneyFormatter(data?.getJobOrder?.salaryMin || 0)}</>
                      ) : (
                        <>
                          {`${moneyFormatter(
                            data?.getJobOrder?.salaryMin || 0
                          )}/hour`}
                        </>
                      )}
                    </h6>
                  </div>

                  <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                    <label>Advertised Salary Max</label>
                    <h6>
                      {' '}
                      {data?.getJobOrder?.rateType === 'ANNUAL' ? (
                        <>{moneyFormatter(data?.getJobOrder?.salaryMax || 0)}</>
                      ) : (
                        <>
                          {`${moneyFormatter(
                            data?.getJobOrder?.salaryMax || 0
                          )}/hour`}
                        </>
                      )}
                    </h6>
                  </div>

                  <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                    <label>Show Salary</label>
                    <h6>
                      {data?.getJobOrder?.showSalary ? 'YES' : 'NO' || '--'}
                    </h6>
                  </div>

                  <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                    <label>Featured Job</label>
                    <h6>
                      {data?.getJobOrder?.isFeatured ? 'YES' : 'NO' || '--'}
                    </h6>
                  </div>

                  <h5 className="mt-3">Advertised Job Description</h5>
                  {data?.getJobOrder?.advertisedJobDescription?.replace(/<(.|\n)*?>/g, '').trim().length ? (
                    <div className="summarySectionJob">
                      <div
                        className="line-clamp"
                        dangerouslySetInnerHTML={createMarkup(
                          data?.getJobOrder?.advertisedJobDescription.trim()
                        )}
                      />

                      <div className="labelTextGrid">
                        <Button
                          className="bg-transparent border-0 p-0 btnLinkColor"
                          onClick={() => {
                            handleJobDescription();
                          }}
                        >
                          View Full Description{' '}
                          <img
                            className="pl-2"
                            src={READ_MORE_ARROW}
                            alt="arrow"
                          />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    '--'
                  )}
                </Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className="jobOrdersActionLog">
              <ActionLogsAndNotes
                option={{
                  jobOrderId: data?.getJobOrder?.id ?? '',
                  jobTitle: data?.getJobOrder?.jobTitle ?? '',
                  companyId: data?.getJobOrder?.company?.id ?? '',
                  companyName: data?.getJobOrder?.company?.name ?? '',
                  contactId:
                    jobMainContact?.getJobOrderMainContact?.contact?.id ?? '',
                  contactName:
                    jobMainContact?.getJobOrderMainContact?.contact?.fullName ??
                    '',
                }}
                type="Job Order"
                recordId={data?.getJobOrder?.id}
              />
            </div>
          </TabPane>

          <TabPane tabId="3">
            <AdvertiseLocationJobOrder
              jobOrderId={data?.getJobOrder?.id}
              reFetchJobOrder={() => {
                getJobOrder({ variables: { id: match?.params?.id } });
              }}
              checkEditPermission={checkEditPermission()}
              setAdvertisedLocationList={setAdvertisedLocationList}
            />
          </TabPane>

          <TabPane tabId="4" className="tab-pane-padding">
            <Row>
              <Col md="5" sm={12}>
                <RecruiterAssigned
                  jobOwner={data?.getJobOrder?.user}
                  teamPermission={teamPermissionMethod()}
                  recruiterList={users?.allUsers}
                  ownerOrShareeOfCompany={ownerOrShareeOfCompany}
                  listOfRecruiters={data?.getJobOrder?.recruitingAssigned}
                  jobOrderId={data?.getJobOrder?.id}
                  recruitingLevel={data?.getJobOrder?.recruitingLevel}
                  assignRecruiter={true}
                  adminExecutiveOwner={checkEditPermissionWithoutSharee()}
                  watchPermission={checkEditPermission()}
                  parallelTeamUsers={
                    parallelTeamUsers?.findingParallelTeamUsers
                  }
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="5">
            <JobOrderClientDocument jobOrderId={data?.getJobOrder?.id} />
          </TabPane>

          <TabPane tabId="6" className="tab-pane-padding">
            <JobApplicantTab jobOrder={data?.getJobOrder} />
          </TabPane>

          <TabPane tabId="7" className="tab-pane-padding">
            <Attachments
              haveActionPersmissions={!checkEditPermission()}
              companyId={data?.getJobOrder?.company?.id}
              attachableType={'Company'}
            />{' '}
          </TabPane>

          <TabPane tabId="8" className="tab-pane-padding">
            <SubmissionHistoryCandidate
              jobOrderId={data?.getJobOrder?.id}
              location={location}
            />
          </TabPane>
          {checkUserFeatureAccess(user?.id, 'JOB_LIST', settingsData) &&
            <TabPane tabId="9" className="tab-pane-padding">
              <JobListNotes
                jobOrderId={data?.getJobOrder?.id}
              />
            </TabPane>}
        </TabContent>
      </div>

      <Modal
        isOpen={confirmToggle}
        toggle={() => setConfirmToggle(!confirmToggle)}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={() => setConfirmToggle(!confirmToggle)}>
          Clone {data?.getJobOrder?.jobTitle}
        </ModalHeader>

        <ModalBody>
          <Label>Company</Label>
          <AsyncCompanyDropdown
            havingOwner={true}
            width={'100%'}
            value={{
              label: selectedCompanyName,
              value: selectedCompanyId,
            }}
            placeholder="Filter By Company name"
            onChange={(value) => {
              setSelectedCompanyId(value?.value);
              setSelectedCompanyName(value?.label);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={() => setConfirmToggle(!confirmToggle)}
            className="buttonPrimary mr-3"
          >
            Cancel
          </button>
          <CustomButton
            buttonText="Clone"
            buttonColor="primary"
            buttonType="button"
            disabled={!selectedCompanyId || duplicating}
            className="big-width"
            buttonClick={() => setCloneModal(true)}
          />
        </ModalFooter>
      </Modal>

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={data?.getJobOrder?.id}
        reFetch={() => _getNotes()}
        type="Job Order"
        editData={noteData}
        resetField={setNoteData}
      />

      <Modal
        isOpen={advertisedJobDescriptionModal}
        toggle={advertisedJobDescriptionToggle}
        style={{ maxWidth: 850 }}
        id="noteModal"
        className="revampDialog viewAdvertised-jobDescription"
      >
        <ModalHeader toggle={advertisedJobDescriptionToggle}>
          View Advertised Job Description
        </ModalHeader>

        <ModalBody style={{ height: 465 }}>
          <div
            className="for-word-break"
            dangerouslySetInnerHTML={createMarkup(advertisedJobDescription)}
          />
        </ModalBody>

        <ModalFooter>
          <CustomButton
            disabled={duplicating}
            buttonText="Close"
            buttonColor="default"
            buttonType="button"
            buttonClick={advertisedJobDescriptionToggle}
            className="btn-primary"
          />
        </ModalFooter>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to clone this job order?"
        isOpen={cloneModal}
        toggle={() => setCloneModal(!cloneModal)}
        confirmAction={duplicateJobOrder}
        modalHeading="Clone This Job Order"
        btnText="Clone"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
      {
        resumeDrawer && (
          <ResumeDrawer
            toggle={handleResumeDrawer}
            isOpen={resumeDrawer}
            submitChat={submitChat}
            messages={messages}
            loading={gptAPI.loading}
            chunkMessage={gptAPI.chunkMessage}
            promptMessage={
              getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, chatGptJobOrderTemplates(promptString))
                ?
                `${replacePlaceholderWithResume(getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, chatGptJobOrderTemplates(promptString)), data?.getJobOrder?.advertisedJobDescription, "{{job_description}}")}`
                : `Please Act As A recruiter, And Provide Me Based on job description,${promptMenu[promptString]}, Here is Job description (ignore HTML Tags): ${data?.getJobOrder?.advertisedJobDescription}.`

            }

            promptMessageForDisplay={`Provide Me Based on job description: ${promptMenu[promptString]}`}
            promptMessageForVerification={
              replacePlaceholderWithResume(getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, chatGptJobOrderTemplates(promptString)), data?.getJobOrder?.advertisedJobDescription, "{{job_description}}") ?? `Please Act As A recruiter, And Provide Me Based on job description,${promptMenu[promptString]}, Here is Job description (ignore HTML Tags):`
            }
            heading={promptMenu[promptString]}
            clearChat={clearChat}
          />
        )
      }
    </div >
  );
};

export default ViewJobOrder;
